@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk&display=swap');

.brand-header {
    display: flex;
    width: 100%;
    margin-top: -6%;
    position: relative;
}

.upper,
.lower,
.left,
.blurr {
    position: absolute;
}

.upper {
    top: -5%;
    right: 0;
    background: linear-gradient(360deg, rgba(223, 226, 228, 0.00) 0%, #DFE2E4 34.9%);
    width: 1440px;
    height: 351px;
}

.lower {
    right: 0;
    bottom: -29%;
    background: linear-gradient(180deg, rgba(223, 226, 228, 0.00) 0%, #DFE2E4 34.9%);
    width: 1000px;
    height: 434px;
}

.left {
    right: 44%;
    bottom: 0;

}

.brand-header-context,
.brand-header-video {
    width: 50%;
    padding-top: 10%;
}

.brand-header-video {
    background: linear-gradient(180deg, rgba(223, 226, 228, 3) 0%, #DFE2E4 34.9%);
}

.brand-header-video video {
    width: 100%;
}

.brand-header-context {
    background-image: url(../../images/assets/BrandIdentity/header-context-bg.png);
    backdrop-filter: blur(4px);
    padding-left: 5%;
    padding-bottom: 5%;
}

.brand-header-context h1 {
    color: #0E8581;
    font-family: 'Familjen Grotesk';
    font-size: 57.702px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    width: 66%;
}

.brand-header-context p {
    color: #0E8581;
    font-family: 'Style Script';
    font-size: 50.104px;
    font-style: normal;
    font-weight: 400;
    line-height: 70.768px;
    text-transform: capitalize;
}

.brand-header-context button {
    display: flex;
    width: 137px;
    height: 41px;
    padding: 6.175px 12.35px 6.175px 13px;
    justify-content: center;
    align-items: center;
    gap: 7.718px;
    flex-shrink: 0;

    border-radius: 30px;
    background: linear-gradient(270deg, #363636 0%, #020202 108.05%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 0.772px 0px rgba(0, 0, 0, 0.10), 0px 1.544px 1.544px 0px rgba(0, 0, 0, 0.09), 0px 3.087px 2.316px 0px rgba(0, 0, 0, 0.05), 0px 6.175px 2.316px 0px rgba(0, 0, 0, 0.01), 0px 9.262px 2.316px 0px rgba(0, 0, 0, 0.00);

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14.949px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.115px;
    /* 141.243% */
    text-transform: capitalize;
}

.brand-header-context button:hover {
    background: linear-gradient(270deg, #17BAB5 0%, #0E8581 108.05%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 0.772px 0px rgba(0, 0, 0, 0.10), 0px 1.544px 1.544px 0px rgba(0, 0, 0, 0.09), 0px 3.087px 2.316px 0px rgba(0, 0, 0, 0.05), 0px 6.175px 2.316px 0px rgba(0, 0, 0, 0.01), 0px 9.262px 2.316px 0px rgba(0, 0, 0, 0.00);
    border: none;
}

.ourwork-main {
    background: #17BAB5;
    backdrop-filter: blur(3.0972321033477783px);
    display: flex;
    height: 720px;
    overflow: hidden;
}

.ourwork-main::before {
    content: '';
    position: absolute;
    background-image: url(../../images/assets/BrandIdentity/workbg.png);
    width: 100%;
    height: 100%;
    right: -24%;
    z-index: -1;
}

.ourwork-context {
    width: 60%;
    padding-left: 5%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
}

.ourwork-context h1 {
    color: #FFF;
    font-family: 'Familjen Grotesk';
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 66px */
    text-transform: capitalize;
}

.ourwork-context p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    width: 80%;
    /* 141.243% */
}

.ourwork-context button {
    display: flex;
    width: 159px;
    height: 40.908px;
    padding: 6.175px 12.35px 6.175px 13px;
    justify-content: center;
    align-items: center;
    gap: 7.718px;
    flex-shrink: 0;

    border: none;
    border-radius: 30px;
    background: linear-gradient(270deg, #363636 0%, #020202 108.05%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 0.772px 0px rgba(0, 0, 0, 0.10), 0px 1.544px 1.544px 0px rgba(0, 0, 0, 0.09), 0px 3.087px 2.316px 0px rgba(0, 0, 0, 0.05), 0px 6.175px 2.316px 0px rgba(0, 0, 0, 0.01), 0px 9.262px 2.316px 0px rgba(0, 0, 0, 0.00);

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14.949px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.115px;
    /* 141.243% */
    letter-spacing: -0.598px;
    text-transform: capitalize;
}

.ourwork-context button:hover {
    background: linear-gradient(270deg, #FFF 0%, #D3D3D3 108.05%);
    color: #000;
    border: none;
}

.ourwork-images {
    display: flex;
    gap: 28px;
    overflow: hidden;
    position: relative;
}

.images-left,
.images-right {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.images-left div,
.images-right div {
    display: flex;
    flex-direction: column;
}

.ourwork-images button {
    display: flex;
    align-self: center;
    width: 75px;
    height: 22px;
    border-radius: 12px;
    border: 1px solid #000;
    padding: 2px 10px;

    background: #000;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 10.376px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.284px;
    /* 156.937% */

    margin-top: -15%;
}


.ourwork-images::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 15%;
    background: linear-gradient(180deg, #4DCAC6 0%, rgba(99, 208, 205, 0) 100%);
    z-index: 1;
}

.ourwork-images::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 15%;
    background: linear-gradient(360deg, #4DCAC6 0%, rgba(99, 208, 205, 0) 100%);
    z-index: 1;
    bottom: 0;
}


.ourwork-images img {
    border-radius: 15px;
    box-shadow: 0px 2.705px 5.41px 0px rgba(17, 151, 149, 0.41), 0px 9.918px 9.918px 0px rgba(17, 151, 149, 0.36), 0px 22.54px 13.524px 0px rgba(17, 151, 149, 0.21), 0px 40.572px 16.229px 0px rgba(17, 151, 149, 0.06), 0px 63.112px 18.032px 0px rgba(17, 151, 149, 0.01);
}

.images-left {
    animation: moveUpLogos 40s infinite linear;
    animation-fill-mode: forwards;
}

.images-left:hover,
.images-right:hover {
    animation-play-state: paused;
}

@keyframes moveUpLogos {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-200%);
    }
}

.images-right {
    animation: moveDownLogos 40s infinite linear;
    animation-fill-mode: forwards;
}

@keyframes moveDownLogos {
    0% {
        transform: translateY(-200%);
    }

    100% {
        transform: translateY(0);
    }
}

.logo-button {
    background: linear-gradient(180deg, #17BAB5 0%, #55A6A5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    position: relative;
    z-index: 1;
    padding: 14px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.logo-button button {
    width: 70px;
    height: 22px;
    border-radius: 20px;
    background: #000;

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 10.376px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.284px;
    /* 156.937% */
}

.wework-main {
    background: #1C1C1C;
    display: flex;
    flex-direction: column;
    height: 1050px;
    position: relative;
    overflow: hidden;
}

.wework-main::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 113%;
    background-image: url(../../images/assets/BrandIdentity/Vector\ 2000px.png);
    background-repeat: no-repeat;
    right: 0%;
    top: -3%;
    z-index: 1;
}

.wework-context {
    padding-left: 5%;
    padding-top: 8%;
    padding-bottom: 10%;
    position: relative;
    z-index: 1;
}

.wework-context h1 {
    color: #A8DDDD;
    font-family: 'Familjen Grotesk';
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
}

.wework-context p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    width: 54%;
}


.package-basic,
.package-standard,
.package-premium {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    position: absolute;
    z-index: 1;
}

.package-basic {
    left: 4%;
    top: 47%;
}

.package-premium {
    right: 9%;
    top: 17.5%;
}

.package-standard {
    left: 43.5%;
    bottom: 13.2%;
}

.package-basic h2,
.package-standard h2,
.package-premium h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.749px;
    /* 174.989% */
    text-transform: capitalize;
}

.package-basic p,
.package-standard p,
.package-premium p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.749px;
    text-transform: capitalize;
    width: 100%;
}

.package-basic button {
    display: flex;
    width: 92.157px;
    height: 29.49px;
    padding: 0.56px 9.392px;
    justify-content: center;
    align-items: center;
    gap: 13.56px;
    flex-shrink: 0;

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14.745px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 16.22px */
    letter-spacing: -0.442px;

    border-radius: 24.575px;
    border: 0.614px solid #FFA925;
    background: #FFD390;
}

.package-standard button {
    display: flex;
    width: 92.157px;
    height: 29.49px;
    padding: 0.56px 9.392px;
    justify-content: center;
    align-items: center;
    gap: 13.56px;
    flex-shrink: 0;

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14.745px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 16.22px */
    letter-spacing: -0.442px;

    border-radius: 24.575px;
    border: 0.614px solid #F9AACD;

    opacity: 0.8;
    background: #F9AACD;
}

.package-premium button {
    display: flex;
    width: 92.157px;
    height: 29.49px;
    padding: 0.56px 9.392px;
    justify-content: center;
    align-items: center;
    gap: 13.56px;
    flex-shrink: 0;

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14.745px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 16.22px */
    letter-spacing: -0.442px;

    border-radius: 24.575px;
    border: 0.614px solid #C0D7FE;
    background: #C0D7FE;


}

.package-basic button:hover,
.package-standard button:hover,
.package-premium button:hover {
    background: #0F9791;
    color: white;
    border: none;
}

.gradient-left {
    width: 198.563px;
    height: 417.337px;
    transform: rotate(50.388deg);
    flex-shrink: 0;
    border-radius: 417.337px;
    background: linear-gradient(180deg, #F9A7A3 0%, #FAC496 64.9%);
    filter: blur(169.28421020507812px);
    position: absolute;
    opacity: 0.4;
    right: -18%;
    top: 27%;
}

.line-mob {
    display: none;
}

.choose-section-main {
    background: #03A6A1;
    display: flex;
    position: relative;
    overflow: hidden;
}

.gradient-first {
    position: absolute;
    width: 297.977px;
    height: 552.035px;
    transform: rotate(53.889deg);
    flex-shrink: 0;

    border-radius: 552.035px;
    background: linear-gradient(180deg, #E0D9F6 0%, #DBA9F3 64.9%);
    filter: blur(236.28421020507812px);

    top: -21%;
    left: 30%;
}

.gradient-second {
    position: absolute;
    width: 297.977px;
    height: 552.035px;
    transform: rotate(53.889deg);
    flex-shrink: 0;
    border-radius: 552.035px;
    background: linear-gradient(180deg, #F9A7A3 0%, #FAC496 64.9%);
    filter: blur(236.28421020507812px);

    top: -21%;
    left: 0;
}

.choose-section-main::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url(../../images/assets/BrandIdentity/choosingbg.png);
    background-position: center;
}

.choose-section-context {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-top: 5%;
    width: 70%;
    position: relative;
    gap: 50px;
    z-index: 1;
}

.choose-section-context::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../images/assets/BrandIdentity/choosing-content-overlay.png);
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    z-index: -1;
}

.choose-section-video video {
    position: absolute;
    width: 96%;
    right: 5px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px
}

.choose-section-video {
    display: flex;
    justify-content: end;
    padding-top: 15%;
    position: relative;
}

.choose-section-video::before {
    content: "";
    position: absolute;
    width: 95%;
    height: 50%;
    bottom: 0;
    opacity: 0.7;
    background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
    z-index: 9;
    right: 8px;
}

.phonemockup {
    width: 100%;
    z-index: 2;
}

.choose-section-context h1 {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, rgba(250, 196, 150, 1), rgba(219, 169, 243, 1));
    background-clip: text;
    font-family: 'Familjen Grotesk';
    font-size: 64.457px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 77.348px */
    text-transform: capitalize;
}

.bullet-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5%;
    gap: 18px;
}

.bullet-heading {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bullet-main h2 {
    color: #1C1C1C;
    font-family: 'Familjen Grotesk';
    font-size: 22.917px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.458px;
}

.bullet-main p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 16.076px;
    font-style: normal;
    font-weight: 300;
    line-height: 25.547px;
    text-transform: capitalize;
    margin: 0;
    width: 40%;
}

.popup {
    position: absolute;
    bottom: 16%;
    right: 5%;
    border-radius: 20px;
    background: #1C1C1C;
    padding: 22px;
}

.popup h2 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.249px;
    /* 158.918% */
    text-transform: capitalize;
    padding-bottom: 15px;
}

.popup p {
    color: #11ABA7;
    font-family: 'Style Script';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.249px;
    /* 69.527% */
    text-transform: capitalize;
}

.popup-side {
    position: absolute;
    right: -11%;
    bottom: 22%;
}

.popup-mob {
    display: none;
}


.brandreviews-main {
    background: #1C1C1C;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-top: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.brandreviews-context {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-left: 1%;
}

.brandreviews-context h1 {
    color: #FFF;
    font-family: 'Familjen Grotesk';
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-transform: capitalize;
}

.brandreviews-context p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 28.249px;
    width: 72%;
}

.brandreviews-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px;
}

.brandreview {
    border-radius: 8px;
    background: #2A2A2A;
    padding: 36px 12px 40px 12px;
}

.brandreview-upper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 4%;
}

.brandreview-p {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.249px;
    /* 158.918% */
    text-transform: capitalize;
}

.brandreview-main .slick-prev:before,
.slick-next:before {
    display: none;
}

.brandreview-main .slick-dots {
    display: block !important;
}

.brandreview-main .slick-dots li button:before {
    color: #17BAB5;
    font-size: 20px;
    top: 20px;
}


.packages-main {
    background-color: #A8DDDD;
    display: flex;
    flex-direction: column;
    padding: 5% 11%;
}

.packages-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.packages-context h1 {
    color: #0E8581;
    text-align: center;
    font-family: 'Familjen Grotesk';
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
}

.packages-context p {
    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    width: 57%;
}

.customers p {
    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    text-transform: capitalize;
    margin: 0;
    width: 100% !important;
}

.customers {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.packages-section {
    display: flex;
    gap: 10px;
    padding-top: 5%;
    padding-bottom: 5%;
    height: 100%;
    align-items: stretch;
}

.brands-package .payment-subsection p {
    color: #333;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 18px */
    letter-spacing: -0.45px;
}

.packages-section-mob {
    display: none;
}


.brands-package {
    background: linear-gradient(rgba(255, 205, 135, 1), rgba(255, 255, 255, 1));
    border-radius: 15px 15px 15px 15px;
}

.brands-package .payment-subsection .subsection-section {
    align-items: center;
}

.brands-package .payment-subsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    padding-bottom: 5%;
}

.brand-header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative
}

.brand-header-div h1 {
    color: #000;
    text-align: center;
    font-family: 'Familjen Grotesk';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
}

.brand-header-div p {
    color: #000;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    letter-spacing: -0.45px;
    width: 74%;
}

.curve {
    position: absolute;
    bottom: -10%;
    right: -1px;
    width: 103%;
}

.brandsubsection {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    gap: 14px;
    padding-top: 20px;
    height: 48%;
}

.brands-package .payment-subsection h2 {
    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 35.147px;
    font-style: normal;
    font-weight: 600;
    line-height: 11.807px;
    /* 33.594% */
    letter-spacing: -1.054px;
}

.second-bg {
    background: linear-gradient(rgba(255, 159, 202, 1), rgba(255, 255, 255, 1));
}

.third-bg {
    background: linear-gradient(rgba(138, 181, 255, 1), rgba(255, 255, 255, 1));
}

.brands-package button {
    display: inline-flex;
    height: 30.003px;
    padding: 7.883px 25.227px;
    justify-content: center;
    align-items: center;
    gap: 7.883px;
    flex-shrink: 0;
}

.first-button {
    border-radius: 20px;
    border: 0.5px solid #FFA925;
    background: #FFD390;
    box-shadow: 0px 5px 12px 0px rgba(255, 211, 144, 0.10), 0px 21px 21px 0px rgba(255, 211, 144, 0.09), 0px 48px 29px 0px rgba(255, 211, 144, 0.05), 0px 86px 34px 0px rgba(255, 211, 144, 0.01), 0px 134px 37px 0px rgba(255, 211, 144, 0.00);
}

.second-button {
    border-radius: 14.287px;
    border: 0.357px solid #FF81B9;
    background: #F9AACD;
    box-shadow: 0px 1.429px 2.857px 0px rgba(149, 70, 98, 0.26), 0px 5.715px 5.715px 0px rgba(149, 70, 98, 0.22), 0px 12.859px 7.858px 0px rgba(149, 70, 98, 0.13), 0px 22.86px 9.287px 0px rgba(149, 70, 98, 0.04), 0px 35.718px 10.001px 0px rgba(149, 70, 98, 0.00);
}

.third-button {
    border-radius: 14.287px;
    border: 0.357px solid #8BB6FF;
    background: #C0D7FE;
    box-shadow: 0px 1.429px 2.143px 0px rgba(85, 135, 216, 0.33), 0px 4.286px 4.286px 0px rgba(85, 135, 216, 0.29), 0px 10.001px 5.715px 0px rgba(85, 135, 216, 0.17), 0px 17.859px 7.144px 0px rgba(85, 135, 216, 0.05), 0px 27.86px 7.858px 0px rgba(85, 135, 216, 0.01);
}

.first-button:hover,
.second-button:hover,
.third-button:hover,
.basicmodal .right-lower button:hover,
.standardmodal .right-lower button:hover,
.premiummodal .right-lower button:hover {
    color: white;
    border: 0.267px solid #074E4B;
    background: #0E8581;
    box-shadow: 0px 5px 12px 0px rgba(23, 186, 181, 0.10), 0px 21px 21px 0px rgba(23, 186, 181, 0.09), 0px 48px 29px 0px rgba(23, 186, 181, 0.05), 0px 86px 34px 0px rgba(23, 186, 181, 0.01), 0px 134px 37px 0px rgba(23, 186, 181, 0.00);
}


.basicmodal .modal-content,
.standardmodal .modal-content,
.premiummodal .modal-content {
    width: 600px;
}

.modal-header {
    background: #0E8581;
    border-bottom: none;
    box-shadow: 0px 1.006px 2.515px 0px rgba(0, 0, 0, 0.10), 0px 4.024px 4.024px 0px rgba(0, 0, 0, 0.09), 0px 9.557px 5.533px 0px rgba(0, 0, 0, 0.05), 0px 17.102px 6.539px 0px rgba(0, 0, 0, 0.01), 0px 26.658px 7.545px 0px rgba(0, 0, 0, 0.00);
    display: flex;
    justify-content: end;
    gap: 25%;
}

.modal-dialog {
    max-width: 700px;
}

.header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 10px;
}

.header-text p {
    color: #FFF;
    font-family: 'Familjen Grotesk';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: capitalize;
    margin: 0;
}


.modal-body {
    width: 100%;
    display: flex;
    padding: 0;
}

.modal-body-left {
    width: 100%;
    padding: 5%;
    padding-top: 8%;
}

.modal-steps {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 58px;
    position: relative;
    z-index: 1;
}


.steps-left {
    display: flex;
    align-items: center;
    gap: 9px;
}

.standardmodal .modal-body-left::before {
    content: '';
    position: absolute;
    width: 3.5%;
    height: 83%;
    left: 15.2%;
    top: 6%;
    border-radius: 85.317px;
    background: #0E8581;
}

.basicmodal .modal-body-left::before {
    content: '';
    position: absolute;
    width: 3.5%;
    height: 79%;
    left: 15.2%;
    top: 7%;
    border-radius: 85.317px;
    background: #0E8581;
}

.premiummodal .modal-body-left::before {
    content: '';
    position: absolute;
    width: 3.5%;
    height: 74%;
    left: 15.2%;
    top: 5%;
    border-radius: 85.317px;
    background: #0E8581;
}

.modal-steps p {
    color: var(--Black, #192533);
    text-align: right;
    font-family: 'Poppins';
    font-size: 11.647px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.modal-steps h2 {
    color: var(--Black, #192533);
    font-family: 'Poppins';
    font-size: 12.479px;
    font-style: normal;
    font-weight: 700;
    line-height: 10.06px;
    /* 133.333% */

}

.modal-steps h3 {
    color: #828282;
    font-family: 'Poppins';
    font-size: 11.647px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.06px;
    /* 142.857% */
}

.modal-body-right {
    background: rgba(255, 211, 146, 0.3);
    width: 70%;
    padding: 3%;
    display: flex;
    flex-direction: column;
}

.standardmodal .modal-body-right {
    background: rgba(249, 170, 205, 0.3);
    width: 69%;
}

.premiummodal .modal-body-right {
    background: rgba(197, 215, 248, 0.3);
    width: 62%;
}

.right-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30%;
}

.premiummodal .right-upper {
    padding-bottom: 5%;
}

.right-upper h2 {
    color: #0E8581;
    font-family: 'Poppins';
    font-size: 14.06px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 8.73px;
}

.right-upper p {
    color: var(--Black, #192533);
    font-family: 'Poppins';
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    line-height: normal;
    text-transform: capitalize;
}

.right-middle h2 {
    color: #2F2C29;
    font-family: 'Familjen Grotesk';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 170%;
    margin: 0;
    letter-spacing: -0.075px;
}

.right-middle p {
    color: #2F2C29;
    font-family: 'Poppins';
    font-size: 12.494px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin: 0;
    letter-spacing: -0.076px;
}

.right-middle-subsection {
    display: flex;
    align-items: center;
    gap: 4.5px;
}

.right-lower {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30%;
}

.right-lower h2 {
    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 26.232px;
    font-style: normal;
    font-weight: 600;
    line-height: 5.328px;
    letter-spacing: -0.476px;
    padding-bottom: 10px;
}

.basicmodal .right-lower button {
    display: flex;
    width: 122.098px;
    height: 22.393px;
    padding: 5.884px 18.828px;
    justify-content: center;
    align-items: center;
    gap: 5.884px;
    flex-shrink: 0;
    border-radius: 10.664px;
    border: 0.161px solid #FFA925;
    background: #FFD390;

    box-shadow: 0px 0.322px 0.645px 0px rgba(253, 221, 175, 0.67), 0px 1.289px 1.289px 0px rgba(253, 221, 175, 0.58), 0px 2.579px 1.612px 0px rgba(253, 221, 175, 0.34), 0px 4.513px 1.934px 0px rgba(253, 221, 175, 0.10), 0px 7.092px 1.934px 0px rgba(253, 221, 175, 0.01);

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 7.981px */
    letter-spacing: -0.218px;
    text-transform: capitalize;
}

.standardmodal .right-lower button {
    display: flex;
    width: 122.098px;
    height: 22.393px;
    padding: 5.884px 18.828px;
    justify-content: center;
    align-items: center;
    gap: 5.884px;
    flex-shrink: 0;

    border-radius: 10.664px;
    border: 0.161px solid #FF81B9;
    background: #F9AACD;
    box-shadow: 0px 0.645px 1.289px 0px rgba(149, 70, 98, 0.26), 0px 2.579px 2.579px 0px rgba(149, 70, 98, 0.22), 0px 5.803px 3.546px 0px rgba(149, 70, 98, 0.13), 0px 10.316px 4.191px 0px rgba(149, 70, 98, 0.04), 0px 16.118px 4.513px 0px rgba(149, 70, 98, 0.00);

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 7.981px */
    letter-spacing: -0.218px;
    text-transform: capitalize;
}

.premiummodal .right-lower button {
    display: flex;
    width: 122.098px;
    height: 22.393px;
    padding: 5.884px 18.828px;
    justify-content: center;
    align-items: center;
    gap: 5.884px;
    flex-shrink: 0;

    border-radius: 10.664px;
    border: 0.161px solid #8BB6FF;
    background: #C0D7FE;
    box-shadow: 0px 0.645px 0.967px 0px rgba(85, 135, 216, 0.33), 0px 1.934px 1.934px 0px rgba(85, 135, 216, 0.29), 0px 4.513px 2.579px 0px rgba(85, 135, 216, 0.17), 0px 8.059px 3.224px 0px rgba(85, 135, 216, 0.05), 0px 12.572px 3.546px 0px rgba(85, 135, 216, 0.01);

    color: #333;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 7.981px */
    letter-spacing: -0.218px;
    text-transform: capitalize;
}

.brandreview-main-mob {
    display: none;
}

.bgg-div {
    background: red;
}

.basicmodal .modal-content {
    margin-top: 16%;
}

.standardmodal .modal-content {
    margin-top: 10%;
}

.premiummodal .modal-content {
    margin-top: 5%;
}

.identity-form form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 5% 0% 5% 5%;
}

.identity-modal .modal-content,
.identity-thankyou .modal-content {
    width: 100%;
}

.identity-thankyou {
    width: 100%;
}

.identity-thankyou .thankyoutxt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 10%;

}

.identity-form form .formcon {
    width: 97%;
}

.identity-form form .formcon .formdata {
    width: 100%;
}

.identity-form form .formcon .formdata input,
.identity-form form .formcon .formdata .inputs,
.identity-form select,
.identity-form .formcon2 {
    width: 95%;
}

.identity-form form .formcon2 input {
    width: 100%;
}

.identity-form form .formcon .formdata .inputs .css-b62m3t-container {
    width: 35%;
}

.identity-text {
    color: #828282;
    font-size: 10px;
    width: 95%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15.506px;
    border-radius: 3.524px;
    background: rgb(0, 0, 0, 0.07);
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
}

.identity-text .checkbox {
    width: 18px;
    height: 15px;
    border-radius: 3px;
    border: 0.509px solid #17BAB5;
    background: #FFF;
}

.identity-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 37%;
    width: 100%;
}

.identity-modal-heading {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
    color: #FFF;
    font-family: 'Familjen Grotesk';
}

@media (max-width:1681px) {
    .lower {
        right: 0;
        bottom: -9%;
    }

    .wework-main {
        height: 1054px;
    }

    .package-basic {
        left: 6%;
        top: 45.5%;
    }

    .package-premium {
        right: 6%;
        top: 18.5%;
    }

    .package-standard {
        left: 45%;
        bottom: 9.2%;
    }
}

@media (max-width: 1601px) {
    .lower {
        right: 0;
        bottom: -12%;
    }

    .package-standard {
        left: 45%;
        bottom: 3.2%;
    }

    .package-premium {
        right: 6%;
        top: 20.5%;
    }
}

@media (max-width: 1585px) {
    .brand-header-context h1 {
        color: #0E8581;
        font-family: 'Familjen Grotesk';
        font-size: 57.702px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
        width: 82%;
    }

    .choose-section-video video {
        position: absolute;
        width: 97%;
        top: 32%;
        right: 5px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
    }

    .blurr {
        position: absolute;
    }

    .upper {
        top: -10%;
        right: 0;
    }

    .lower {
        right: 0;
        bottom: -18%;
    }

    .left {
        right: 44%;
        bottom: 0;

    }

    .ourwork-main::before {
        content: '';
        position: absolute;
        background-image: url(../../images/assets/BrandIdentity/workbg.png);
        width: 100%;
        height: 100%;
        right: -70px;
        z-index: -1;
    }

    .package-basic {
        left: 3.4%;
        top: 43.5%;
    }

    .package-premium {
        right: 9.3%;
        top: 21%;
    }

    .package-standard {
        left: 42.5%;
        bottom: 3.6%;
    }

    .wework-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 113%;
        background-image: url(../../images/assets/BrandIdentity/Vector\ 2000px.png);
        background-repeat: no-repeat;
        right: 0%;
        top: -8%;
        z-index: 1;
    }

    .gradient-left {
        width: 198.563px;
        height: 417.337px;
        transform: rotate(50.388deg);
        flex-shrink: 0;
        border-radius: 417.337px;
        background: linear-gradient(180deg, #F9A7A3 0%, #FAC496 64.9%);
        filter: blur(169.28421020507812px);
        position: absolute;
        opacity: 0.4;
        right: -77%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 3.5%;
        height: 75%;
        left: 15.2%;
        top: 4%;
        border-radius: 85.317px;
        background: #0E8581;
    }

    .basicmodal .modal-content {
        margin-top: 6%;
    }

    .standardmodal .modal-content {
        margin-top: 1%;
    }

    .premiummodal .modal-content {
        margin-top: 0;
    }

}

@media (max-width: 1537px) {

    .package-premium {
        right: 6.3%;
        top: 21%;
    }

    .package-standard {
        left: 44%;
        bottom: 3.6%;
    }

    .choose-section-video video {
        position: absolute;
        width: 97%;
        top: 31%;
        right: 5px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
    }
}

@media (max-width: 1441px) {
    .lower {
        bottom: -31%;
        right: 0;
    }

    .wework-main {
        height: 1108px;
    }

    .packages-main {
        background-color: #A8DDDD;
        display: flex;
        flex-direction: column;
        padding: 5% 9%;
    }

    .package-basic {
        left: 3.4%;
        top: 43.3%;
    }

    .package-standard {
        left: 43%;
        bottom: 4.2%;
    }

    .package-premium {
        right: 1.4%;
        top: 24%;
    }

    .choose-section-video video {
        top: 30%;
    }

    .wework-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 113%;
        background-image: url(../../images/assets/BrandIdentity/Vector\ 2000px.png);
        background-repeat: no-repeat;
        right: 0%;
        top: -6%;
        z-index: 1;
    }
}

@media (max-width: 1401px) {
    .package-premium {
        right: 13%;
        top: 29%;
    }

    .choose-section-video {
        display: flex;
        justify-content: end;
        padding-top: 16%;
        position: relative;
    }

    .brand-header-div p {
        font-size: 14px;
    }
}

@media (max-width: 1381px) {
    .wework-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 113%;
        background-image: url(../../images/assets/BrandIdentity/Vector\ 2000px.png);
        background-repeat: no-repeat;
        right: 0%;
        top: -10%;
        z-index: 1;
    }

    .package-basic {
        left: 3.4%;
        top: 39.3%;
    }

    .package-standard {
        left: 43%;
        bottom: 8.2%;
    }

    .package-premium {
        right: 13%;
        top: 24%;
    }

    .choose-section-video {
        padding-top: 17%;
    }

    .choose-section-video video {
        top: 31.5%;
    }
}

@media (max-width: 1367px) {

    .basicmodal .modal-content {
        margin-top: 0%;
    }

    .standardmodal .modal-content {
        margin-top: 0%;
    }

    .premiummodal .modal-content {
        margin-top: 0%;
    }

    .wework-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 113%;
        background-image: url(../../images/assets/BrandIdentity/Vector\ 2000px.png);
        background-repeat: no-repeat;
        right: 0%;
        top: -6%;
        z-index: 1;
    }

    .lower {
        right: 0;
        bottom: -30%;
    }

    .brand-header-context h1 {
        color: #0E8581;
        font-family: 'Familjen Grotesk';
        font-size: 55.702px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
        width: 78%;
    }

    .brand-header {
        display: flex;
        width: 100%;
        margin-top: -7%;
        position: relative;
    }

    .ourwork-context {
        width: 53%;
    }

    .packages-main {
        padding: 5%;
    }

    .packages-context p {
        width: 60%;
    }

    .package-basic {
        left: 4%;
        top: 42.6%;
    }

    .package-standard {
        left: 37%;
        bottom: 7.2%;
    }

    .package-premium {
        right: 13%;
        top: 27.5%;
    }

    .phonemockup {
        width: 107%;
        height: 100%;
        z-index: 2;
    }

    .popup {
        right: 75px;
    }

    .choose-section-video video {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        position: absolute;
        right: 2px;
        top: 31%;
        width: 101%;
    }

    .choose-section-video:before {
        background: linear-gradient(1turn, #079c99, rgba(99, 208, 205, 0));
        bottom: 0;
        content: "";
        height: 50%;
        opacity: .7;
        position: absolute;
        right: 8px;
        width: 101%;
        z-index: 9;
    }
}

@media (max-width: 1281px) {
    .brand-header-context {
        width: 52%;
    }

    .blurr {
        position: absolute;
        left: -1%;
    }

    .lower {
        right: 0;
        bottom: -14%;
    }

    .packages-main {
        padding: 3%;
    }

    .package-standard {
        left: 39%;
        bottom: 7.2%;
    }

    .choose-section-video video {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        position: absolute;
        right: 5px;
        top: 29%;
        width: 103%;
    }

    .brandreview-p {
        font-size: 11px;
    }

    .package-premium {
        right: 11%;
        top: 27.2%;
    }
}

@media (max-width: 1025px) {
    .brand-header {
        margin-top: -9%;
    }

    .brand-header-context h1 {
        font-size: 40.702px;
    }

    .upper {
        top: -24%;
        right: 0;
    }

    .lower {
        right: 0;
        bottom: -36%;
    }

    .blurr {
        position: absolute;
        left: -14%;
    }

    .packages-section {
        display: none;
    }

    .packages-section-mob {
        display: block;
        position: relative;
        z-index: 3;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 5%;
        padding-bottom: 20%;
    }

    .packages-section-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .packages-section-mob .slick-dots {
        display: block !important;
        bottom: -7%;
    }

    .packages-section-mob .slick-dots li button::before {
        color: #17BAB5;
        font-size: 20px;

    }

    .curve {
        display: none !important;
    }

    .wework-main {
        height: 1152px;
    }

    .package-basic {
        left: 4%;
        top: 41.6%;
    }

    .package-standard {
        left: 32%;
        bottom: 2.2%;
    }

    .package-premium {
        right: 2%;
        top: 36.8%;
    }

    .choose-section-context::before {
        content: "";
        position: absolute;
        width: 117%;
        height: 100%;
        background-image: url(http://localhost:3000/static/media/choosing-content-overlay.20be3d3….png);
        background-repeat: no-repeat;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .popup h2 {
        color: #FFF;
        font-family: 'Poppins';
        font-size: 9px;
        font-style: normal;
        font-weight: 300;
        line-height: 22.249px;
        text-transform: capitalize;
        padding-bottom: 15px;
    }

    .popup p {
        font-size: 25px;
    }

    .choose-section-video {
        padding-top: 25%;
        padding-right: 5%;
    }

    .phonemockup {
        width: 123%;
        height: 100%;
        z-index: 2;
    }

    .choose-section-video video {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        position: absolute;
        right: 17%;
        top: 32%;
        width: 93%;
    }

    .choose-section-video:before {
        background: linear-gradient(1turn, #079c99, rgba(99, 208, 205, 0));
        bottom: 0;
        content: "";
        height: 50%;
        opacity: .7;
        position: absolute;
        right: 8px;
        width: 107.5%;
        z-index: 9;
    }

    .brandreview-p {
        font-size: 10px;
    }

    .icons {
        padding-right: 23%;
    }

    .brands-package {
        height: 758px;
    }

    .payment-subsection {
        padding-top: 5%;
    }

    .brandreview-main {
        display: none;
    }

    .brandreview-main-mob {
        display: block;
    }

    .brandreviews-grid-mob {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 34px;
    }

    .brandreview-main-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .brandreview-main-mob .slick-dots {
        display: block !important;
    }

    .brandreview-main-mob .slick-dots li {
        color: #17BAB5;
        font-size: 20px;
        top: 10px;
    }

    .brandreview-main-mob .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }


    .brandreviews-main {
        padding-bottom: 15%;
    }
}

@media (max-width: 915px) {
    .brand-header {
        margin-top: -10%;
    }

    .brands-package {
        height: 961px;
    }

    .payment-subsection {
        padding-top: 0;
    }

    .brand-header-div img {
        width: 100%;
    }

    .blurr {
        position: absolute;
        left: -21%;
    }

    .packages-section-mob {
        padding-top: 10%;
    }

    .ourwork-images {
        padding-right: 5%;
    }

    .choose-section-video {
        padding-top: 36%;
        padding-right: 5%;
    }

    .choose-section-video video {
        top: 39%;
    }

    .popup {
        right: 70px;
        bottom: 3%;
        padding: 12px;
    }

    .package-standard {
        left: 35%;
        bottom: 2.2%;
    }

    .package-premium {
        right: 4%;
        top: 41.5%;
    }

    .brandreview-upper {
        align-items: center;
    }
}

@media (max-width: 901px) {
    .brand-header-video video {
        width: 100%;
        padding-top: 13%;
    }

    .brand-header-context {
        width: 56%;
    }

    .lower {
        right: 0;
        bottom: -39%;
    }

    .upper {
        top: -19%;
        right: 0;
    }

    .choose-section-video video {
        top: 38%;
        width: 94%;
    }

    .choose-section-video:before {
        width: 108.5%;
    }
}

@media (max-width: 885px) {
    .lower {
        right: 0;
        bottom: -47%;
    }

    .package-premium {
        right: 4%;
        top: 40.5%;
    }

    .package-basic {
        left: 5%;
        top: 41.6%;
    }

    .package-standard {
        left: 36%;
        bottom: 2.2%;
    }

    .choose-section-video video {
        top: 38%;
        width: 96%;
        right: 16%;
    }

    .choose-section-video:before {
        width: 109.5%;
    }
}

@media (max-width: 858px) {

    .brand-header-context,
    .brand-header-video {
        width: 50%;
        padding-top: 15%;
    }

    .upper {
        top: -4%;
        right: 0;
    }

    .blurr {
        position: absolute;
        left: -29%;
    }

    .lower {
        right: 0;
        bottom: -30%;
    }

    .ourwork-images {
        padding-right: 10%;
    }

    .package-standard {
        left: 37%;
        bottom: 2.2%;
    }

    .package-premium {
        right: 1%;
        top: 40.5%;
    }

    .choose-section-video {
        padding-top: 36%;
        padding-right: 1%;
    }

    .choose-section-video video {
        top: 37%;
        width: 107%;
        right: 6%;
    }

    .brandreview-main {
        display: none;
    }

    .brandreview-main-mob {
        display: block;
    }

    .brandreviews-grid-mob {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 34px;
    }

    .brandreview-main-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .brandreview-main-mob .slick-dots {
        display: block !important;
    }

    .brandreview-main-mob .slick-dots li {
        color: #17BAB5;
        font-size: 20px;
        top: 10px;
    }

    .brandreview-main-mob .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }
}

@media (max-width: 840px) {
    .package-premium {
        right: 2%;
        top: 39.5%;
    }

    .package-standard {
        left: 38%;
        bottom: 2.2%;
    }

    .choose-section-video video {
        top: 35%;
        width: 113%;
        right: 6%;
    }

    .choose-section-video:before {
        width: 116.5%;
    }

    .popup-side {
        position: absolute;
        right: -3%;
        bottom: 22%;
    }
}

@media (max-width: 821px) {
    .choose-section-video:before {
        width: 105.5%;
        right: 16px;
    }

    .popup {
        bottom: 3%;
        padding: 12px;
        right: 54px;
    }

    .brand-header {
        margin-top: -11%;
    }

    .blurr {
        position: absolute;
        left: -33%;
    }

    .choose-section-video video {
        right: 6%;
        top: 40%;
        width: 106%;
    }

    .choose-section-video {
        padding-top: 42%;
        padding-right: 1%;
    }

    .brand-header-video video {
        width: 100%;
        padding-top: 24%;
    }

    .package-standard {
        left: 38%;
        bottom: 2.6%;
    }

    .package-premium {
        right: 2%;
        top: 39.2%;
    }

}

@media (max-width: 811px) {
    .choose-section-video:before {
        width: 112.5%;
        right: 16px;
    }

    .blurr {
        position: absolute;
        left: -31%;
    }

    .brand-header-video video {
        width: 100%;
        padding-top: 27%;
    }

    .choose-section-video {
        padding-top: 41%;
        padding-right: 1%;
    }

    .choose-section-video video {
        top: 37.5%;
        width: 113%;
        right: 6%;
    }

    .popup h2 {
        font-size: 8px;
    }

    #header {
        width: 100%;
        background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
        position: relative;
        margin-top: -10.5%;
    }

    .popup {
        bottom: 3%;
        padding: 12px;
        right: 75px;
    }
}

@media (max-width: 801px) {
    .choose-section-video {
        padding-top: 43%;
        padding-right: 1%;
    }

    .choose-section-video:before {
        width: 113.5%;
        right: 16px;
    }

    .choose-section-video video {
        top: 38%;
        width: 113%;
        right: 6%;
    }

    .package-standard {
        left: 40%;
        bottom: 2.2%;
    }

    .brandreview-p {
        font-size: 12px;
    }
}

@media (max-width: 769px) {
    .identity-modal-heading {
        font-size: 12.5px;
    }

    .identity-form form .formcon {
        width: 100%;
    }

    .choose-section-context::before {
        display: none;
    }

    .brand-header {
        flex-direction: column;
        margin-top: -22%;
    }

    .brand-header-context {
        width: 100%;
        padding-top: 30%;
        background: #B2CCCD;
    }

    .brand-header-video {
        width: 100%;
        padding-top: 0;
        display: flex;
    }

    .brand-header-video video {
        width: 100%;
        padding-top: 0%;
    }

    .upper {
        top: 31%;
        right: 0;
    }

    .left {
        right: 60%;
        bottom: 0;
    }

    .blurr {
        display: none;
    }

    .lower {
        right: 0;
        bottom: -36%;
        width: 100%;
    }

    .brand-header-context h1 {
        color: #0E8581;
        font-family: 'Familjen Grotesk';
        font-size: 37.258px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
    }

    .brand-header-context p {
        color: #0E8581;
        font-family: 'Style Script';
        font-size: 30.041px;
        font-style: normal;
        font-weight: 400;
        line-height: 42.431px;
        /* 141.243% */
        text-transform: capitalize;
    }

    .ourwork-main {
        flex-direction: column;
        gap: 40px;
        height: 927px;
    }

    .ourwork-main::before {
        display: none;
    }

    .ourwork-context {
        width: 100%;
        padding-top: 9%;
    }

    .ourwork-context h1 {
        font-size: 28px;
    }

    .ourwork-context p {
        font-size: 15px;
        width: 100%;
        line-height: 20.09px
    }

    .ourwork-images {
        flex-direction: column;
        overflow: visible;
    }

    .ourwork-images::before {
        display: none;
    }

    .ourwork-images::after {
        display: none;
    }

    .images-left,
    .images-right {
        flex-direction: row;
    }

    .images-left:hover,
    .images-right:hover {
        animation-play-state: running;
    }

    @keyframes moveUpLogos {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-200%);
        }
    }

    @keyframes moveDownLogos {
        0% {
            transform: translateX(-200%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .packages-main {
        padding: 3%;
        padding-top: 20%;
    }

    .packages-context h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .packages-context p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.249px;
        width: 100%;
    }

    .brands-package .subsection-section p {
        color: #333;
        font-family: 'Poppins';
        font-size: 11.52px;
        font-style: normal;
        font-weight: 400;
        line-height: 220%;
        /* 25.344px */
        letter-spacing: -0.115px;
    }

    .brandsubsection {
        gap: 0px;
    }

    .packages-section-mob .brands-package {
        height: 800px;
    }


    .wework-main {
        height: 960px;
    }

    .wework-main::before {
        content: '';
        position: absolute;
        background-image: url(../../images/assets/BrandIdentity/vector-pad.png);
        width: 105%;
        height: 100%;
        top: 28%;
    }

    .wework-context h1 {
        font-size: 28px;
        line-height: 120%;
    }

    .wework-context p {
        font-size: 15px;
        line-height: 19.166px;
        width: 85%;
    }

    .package-basic,
    .package-standard,
    .package-premium {
        width: 150px;
    }

    .package-basic h2,
    .package-standard h2,
    .package-premium h2 {
        font-size: 10px;
    }

    .package-basic p,
    .package-standard p,
    .package-premium p {
        font-size: 9px;
        line-height: 13.392px;
        width: 100%;
    }

    .package-basic button,
    .package-standard button,
    .package-premium button {
        display: flex;
        width: 54.252px;
        height: 17.361px;
        justify-content: center;
        align-items: center;
        gap: 7.983px;
        flex-shrink: 0;

        color: #333;
        text-align: center;
        font-family: 'Poppins';
        font-size: 7.68px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 9.548px */
        letter-spacing: -0.26px;
    }


    .line-mob {
        display: block;
    }

    .line-web {
        display: none;
    }

    .package-basic {
        left: 10%;
        top: 54.7%;
    }

    .package-standard {
        left: 42%;
        bottom: 10.5%;
    }

    .package-premium {
        right: 20%;
        top: 26.5%;
    }


    .choose-section-main {
        flex-direction: column;
    }

    .choose-section-context {
        width: 100%;
        gap: 30px;
    }

    .choose-section-context h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .choose-section-video::before {
        content: "";
        position: absolute;
        width: 39%;
        height: 50%;
        bottom: 0px;
        opacity: 0.7;
        background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
        z-index: 9;
        right: 45px;
    }

    .bullet-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 15%;
        gap: 12px;
    }

    .bullet-main h2 {
        font-size: 16px;
    }

    .bullet-main p {
        width: 65%;
        font-size: 11px;
        line-height: 18.239px;
    }

    .choose-section-video {
        justify-content: end;
        padding-right: 10%;
        padding-top: 5%;
    }

    .phonemockup {
        width: 40%;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 82px;
        border-top-right-radius: 35px;
        border-top-left-radius: 41px;
        top: 42px;
    }

    .popup {
        display: none;
    }

    .popup-mob {
        display: block;
        position: absolute;
        bottom: 11%;
        right: 57%;
        border-radius: 20px;
        background: #1C1C1C;
        padding: 12px 8px 7px 10px;
        width: 166px;
        z-index: 2;
    }

    .popup-mob h2 {
        color: #FFF;
        font-family: 'Poppins';
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 13.237px;
        /* 132.37% */
        text-transform: capitalize;
        padding-bottom: 5px;
    }

    .popup-mob p {
        color: #11ABA7;
        font-family: 'Style Script';
        font-size: 13.949px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.698px;
        /* 69.527% */
        text-transform: capitalize;
        width: fit-content;
    }

    .popup-side {
        position: absolute;
        right: -12%;
        bottom: 22%;
    }

    .choose-section-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-image: url(../../images/assets/BrandIdentity/choosingbg-mob.png);
        background-position: top;
        background-repeat: no-repeat;
    }

    .choose-section-context {
        padding-top: 10%;
    }

    .wework-context {
        padding-top: 12%;
    }

    .packages-section {
        display: none;
    }

    .packages-section-mob {
        display: block;
        position: relative;
        z-index: 3;
        width: 82%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15%;
        padding-bottom: 20%;
    }

    .packages-section-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .packages-section-mob .slick-dots {
        display: block !important;
        bottom: -7%;
    }

    .packages-section-mob .slick-dots li button::before {
        color: #17BAB5;
        font-size: 20px;

    }

    .brand-header-div p {
        font-size: 11.52px;
        line-height: 16.512px;
    }

    .curve {
        display: none !important;
    }

    .brandreviews-main {
        padding-top: 12%;
    }

    .brandreviews-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .brandreviews-context {
        gap: 12px;
    }

    .brandreviews-context h1 {
        font-size: 18.194px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .brandreviews-context p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.2px;
        width: 100%;
    }

    .right-upper {
        padding-bottom: 20%;
    }

    .header-text p {
        font-size: 12.59px;
    }

    .modal-steps {
        padding-bottom: 15px;
    }

    .steps-left img {
        width: 25px;
        height: 25px;
    }

    .modal-steps p,
    .modal-steps h3,
    .modal-steps h2,

    .right-upper p,
    .right-middle p,
    .right-middle h2 {
        font-size: 6.7px;
    }

    .right-upper h2 {
        font-size: 10px;
    }

    .right-upper p {
        font-size: 11px;
    }

    .basicmodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .standardmodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .premiummodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .premiummodal .modal-body-right {
        background: rgba(197, 215, 248, 0.3);
        width: 60%;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 78%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 11.1%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 11.1%;
        top: 7%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 11.2%;
        top: 8%;
    }

    .modal-body-left {
        width: 70%;
    }

    .brandreview-main {
        display: none;
    }

    .brandreview-main-mob {
        display: block;
    }

    .brandreviews-grid-mob {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 34px;
    }

    .brandreview-main-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .brandreview-main-mob .slick-dots {
        display: block !important;
    }

    .brandreview-main-mob .slick-dots li {
        color: #17BAB5;
        font-size: 20px;
        top: 10px;
    }

    .brandreview-main-mob .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }

    .brandreview-p {
        font-size: 9.044px;
    }

    .brandreviews-main {
        padding-bottom: 15%;
    }

    .modal-body-right {
        width: 65%;
    }

}

@media (max-width: 713px) {
    .choose-section-video video {
        right: 73px;
        top: 38px;
    }

    .package-premium {
        right: 13%;
        top: 26.5%;
    }

    .package-standard {
        left: 42%;
        bottom: 13.2%;
    }

    .package-basic {
        left: 11%;
        top: 54.7%;
    }
}

@media (max-width: 641px) {
    .package-basic {
        left: 13%;
        top: 54.7%;
    }

    .package-standard {
        left: 46%;
        bottom: 13.6%;
    }

    .package-premium {
        right: 3%;
        top: 26.5%;
    }

    .choose-section-video video {
        right: 67px;
        top: 36px;
    }
}

@media (max-width: 616px) {
    .wework-main::before {
        top: 21%;
    }

    .package-basic {
        left: 10%;
        top: 51.2%;
    }

    .package-standard {
        left: 36%;
        bottom: 10.5%;
    }

    .package-premium {
        right: 4.5%;
        top: 26.5%;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 65px;
        border-top-right-radius: 35px;
        border-top-left-radius: 41px;
        top: 33px;
    }
}

@media (max-width: 601px) {
    .choose-section-video video {
        right: 63px;
    }

    .identity-thankyou .modal.show .modal-dialog {
        margin-top: 60%;
    }

    .identity-form form .formcon .formdata .inputs .css-b62m3t-container {
        width: 28%;
    }

    .package-premium {
        right: 2%;
        top: 26.5%;
    }

    .package-standard {
        left: 37%;
        bottom: 10.5%;
    }

    .package-basic {
        left: 10%;
        top: 51.5%;
    }
}

@media (max-width: 541px) {
    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 56px;
        border-top-right-radius: 35px;
        border-top-left-radius: 41px;
        top: 28px;
    }

    .package-basic {
        left: 12%;
        top: 51.5%;
    }

    .package-standard {
        left: 41.5%;
        bottom: 10.5%;
    }

    .package-premium {
        right: 14%;
        top: 31.5%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 12.2%;
        top: 8%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 12.1%;
        top: 7%;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2%;
        height: 78%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 12.1%;
    }
}

@media (max-width: 501px) {
    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 52px;
        border-top-right-radius: 35px;
        border-top-left-radius: 35px;
        top: 26px;
    }

    .package-standard {
        left: 45.4%;
        bottom: 10.5%;
    }

    .package-premium {
        right: 11%;
        top: 30.8%;
    }

    .package-basic {
        left: 13%;
        top: 51.5%;
    }

    .premiummodal .modal-body-left::before {
        left: 12.5%;
    }

    .standardmodal .modal-body-left::before {
        left: 12.5%;
    }
}

@media (max-width: 481px) {
    .packages-section-mob .brands-package {
        height: 690px;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 50px;
        border-top-right-radius: 31px;
        border-top-left-radius: 32px;
        top: 26px;
    }

    .package-basic {
        left: 14%;
        top: 51.5%;
    }

    .package-standard {
        left: 47.4%;
        bottom: 10.5%;
    }

    .package-premium {
        right: 8%;
        top: 30.8%;
    }

    .header-div-img {
        width: 100%;
    }

    .premiummodal .modal-body-left::before {
        left: 13.1%;
    }

    .standardmodal .modal-body-left::before {
        left: 13.1%;
    }

    .basicmodal .modal-body-left::before {
        left: 13.1%;
    }
}

@media (max-width: 449px) {
    .package-basic {
        left: 15.5%;
        top: 51.5%;
    }

    .package-standard {
        left: 47.4%;
        bottom: 6.5%;
    }

    .package-premium {
        right: 8%;
        top: 32.8%;
    }

    .basicmodal .modal-body-left::before {
        left: 13.8%;
    }

    .standardmodal .modal-body-left::before {
        left: 13.8%;
    }

    .premiummodal .modal-body-left::before {
        left: 13.8%;
        top: 7%;
    }

    #header {
        margin-top: -16.5%;
    }

    .choose-section-video video {
        right: 47px;
    }
}

@media (max-width: 431px) {
    .identity-form form .formcon .formdata .inputs .css-b62m3t-container {
        width: 22%;
    }

    .brand-header-context button {
        margin-left: 0%;
    }

    .choose-section-context::before {
        display: none;
    }

    .brand-header {
        flex-direction: column;
        margin-top: -22%;
    }

    .brand-header-context {
        width: 100%;
        padding-top: 30%;
        background-image: url(../../images/assets/BrandIdentity/header-bg-mob.png);
        background-repeat: no-repeat;
    }

    .brand-header-video {
        width: 100%;
        padding-top: 0;
        display: flex;
    }

    .upper {
        top: 31%;
        right: 0;
    }

    .left {
        right: 60%;
        bottom: 0;
    }

    .blurr {
        display: none;
    }

    .lower {
        right: 0;
        bottom: -36%;
        width: 100%;
    }

    .brand-header-context h1 {
        color: #0E8581;
        font-family: 'Familjen Grotesk';
        font-size: 37.258px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: capitalize;
    }

    .brand-header-context p {
        color: #0E8581;
        font-family: 'Style Script';
        font-size: 30.041px;
        font-style: normal;
        font-weight: 400;
        line-height: 42.431px;
        /* 141.243% */
        text-transform: capitalize;
    }

    .ourwork-main {
        flex-direction: column;
        gap: 40px;
        height: 927px;
        background-image: url(../../images/assets/BrandIdentity/ourworkbg-mob.png);
    }

    .ourwork-main::before {
        display: none;
    }

    .ourwork-context {
        width: 100%;
        padding-top: 20%;
    }

    .ourwork-context h1 {
        font-size: 28px;
    }

    .ourwork-context p {
        font-size: 15px;
        width: 100%;
        line-height: 20.09px
    }

    .ourwork-images {
        flex-direction: column;
        overflow: visible;
    }

    .ourwork-images::before {
        display: none;
    }

    .ourwork-images::after {
        display: none;
    }

    .images-left,
    .images-right {
        flex-direction: row;
    }

    .images-left:hover,
    .images-right:hover {
        animation-play-state: running;
    }

    @keyframes moveUpLogos {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-200%);
        }
    }

    @keyframes moveDownLogos {
        0% {
            transform: translateX(-200%);
        }

        100% {
            transform: translateX(0);
        }
    }

    .packages-main {
        padding: 3%;
        padding-top: 20%;
    }

    .packages-context h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .packages-context p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.249px;
        width: 100%;
    }

    .brands-package .subsection-section p {
        color: #333;
        font-family: 'Poppins';
        font-size: 11.52px;
        font-style: normal;
        font-weight: 400;
        line-height: 220%;
        /* 25.344px */
        letter-spacing: -0.115px;
    }

    .brandsubsection {
        gap: 0px;
    }

    .packages-section-mob .brands-package {
        height: 687px;
    }


    .wework-main {
        height: 960px;
    }

    .wework-main::before {
        content: '';
        position: absolute;
        background-image: url(../../images/assets/BrandIdentity/vector-phone.svg);
        width: 105%;
        height: 100%;
        top: 42%;
    }

    .wework-context h1 {
        font-size: 28px;
        line-height: 120%;
    }

    .wework-context p {
        font-size: 15px;
        line-height: 19.166px;
        width: 85%;
    }

    .package-basic,
    .package-standard,
    .package-premium {
        width: 150px;
    }

    .package-basic h2,
    .package-standard h2,
    .package-premium h2 {
        font-size: 10px;
    }

    .package-basic p,
    .package-standard p,
    .package-premium p {
        font-size: 9px;
        line-height: 13.392px;
        width: 100%;
    }

    .package-basic button,
    .package-standard button,
    .package-premium button {
        display: flex;
        width: 54.252px;
        height: 17.361px;
        justify-content: center;
        align-items: center;
        gap: 7.983px;
        flex-shrink: 0;

        color: #333;
        text-align: center;
        font-family: 'Poppins';
        font-size: 7.68px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 9.548px */
        letter-spacing: -0.26px;
    }


    .line-mob {
        display: block;
    }

    .line-web {
        display: none;
    }

    .package-basic {
        left: 3%;
        top: 47.7%;
    }

    .package-standard {
        left: 33%;
        bottom: 14.5%;
    }

    .package-premium {
        right: 5%;
        top: 28.5%;
    }


    .choose-section-main {
        flex-direction: column;
    }

    .choose-section-context {
        width: 100%;
        gap: 30px;
    }

    .choose-section-context h1 {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .choose-section-video::before {
        content: "";
        position: absolute;
        width: 35%;
        height: 50%;
        bottom: 0px;
        opacity: 0.7;
        background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
        z-index: 9;
        right: 45px;
    }

    .bullet-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 15%;
        gap: 12px;
    }

    .bullet-main h2 {
        font-size: 16px;
    }

    .bullet-main p {
        width: 65%;
        font-size: 11px;
        line-height: 18.239px;
    }

    .choose-section-video {
        justify-content: end;
        padding-right: 10%;
        padding-top: 5%;
    }

    .phonemockup {
        width: 40%;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 45px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        top: 24px;
    }

    .popup {
        display: none;
    }

    .popup-mob {
        display: block;
        position: absolute;
        bottom: 11%;
        right: 57%;
        border-radius: 20px;
        background: #1C1C1C;
        padding: 12px 8px 7px 10px;
        width: 130px;
        z-index: 2;
    }

    .popup-mob h2 {
        color: #FFF;
        font-family: 'Poppins';
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 13.237px;
        /* 132.37% */
        text-transform: capitalize;
        padding-bottom: 5px;
    }

    .popup-mob p {
        color: #11ABA7;
        font-family: 'Style Script';
        font-size: 13.949px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.698px;
        /* 69.527% */
        text-transform: capitalize;
        width: fit-content;
    }

    .popup-side {
        z-index: -1;
    }

    .choose-section-main::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        background-image: url(../../images/assets/BrandIdentity/choosingbg-mob.png);
        background-position: top;
        background-repeat: no-repeat;
    }

    .choose-section-context {
        padding-top: 10%;
    }

    .wework-context {
        padding-top: 12%;
    }

    .packages-section {
        display: none;
    }

    .packages-section-mob {
        display: block;
        position: relative;
        z-index: 3;
        width: 82%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15%;
        padding-bottom: 20%;
    }

    .packages-section-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .packages-section-mob .slick-dots {
        display: block !important;
        bottom: -7%;
    }

    .packages-section-mob .slick-dots li button::before {
        color: #17BAB5;
        font-size: 20px;

    }

    .brand-header-div p {
        font-size: 10.52px;
        line-height: 16.512px;
    }

    .curve {
        display: none !important;
    }

    .brandreviews-main {
        padding-top: 12%;
    }

    .brandreviews-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .brandreviews-context {
        gap: 12px;
    }

    .brandreviews-context h1 {
        font-size: 18.194px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }

    .brandreviews-context p {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28.2px;
        width: 100%;
    }

    .right-upper {
        padding-bottom: 20%;
    }

    .header-text p {
        font-size: 12.59px;
    }

    .modal-steps {
        padding-bottom: 15px;
    }

    .steps-left img {
        width: 25px;
        height: 25px;
    }

    .modal-steps p,
    .modal-steps h3,
    .modal-steps h2,

    .right-upper p,
    .right-middle p,
    .right-middle h2 {
        font-size: 6.7px;
    }

    .right-upper h2 {
        font-size: 10px;
    }

    .right-upper p {
        font-size: 11px;
    }

    .basicmodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .standardmodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .premiummodal .right-lower button {
        width: 90.822px;
        height: 18.539px;
        font-size: 9.255px;
    }

    .premiummodal .modal-body-right {
        background: rgba(197, 215, 248, 0.3);
        width: 60%;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2.5%;
        height: 78%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.1%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2.5%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.1%;
        top: 7%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        width: 2.55%;
        height: 80%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14%;
        top: 6%;
    }

    .modal-body-left {
        width: 70%;
    }

    .brandreview-main {
        display: none;
    }

    .brandreview-main-mob {
        display: block;
    }

    .brandreviews-grid-mob {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 34px;
    }

    .brandreview-main-mob .slick-prev:before,
    .slick-next:before {
        display: none;
    }

    .brandreview-main-mob .slick-dots {
        display: block !important;
    }

    .brandreview-main-mob .slick-dots li {
        color: #17BAB5;
        font-size: 20px;
        top: 10px;
    }

    .brandreview-main-mob .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }

    .brandreview-p {
        font-size: 12.044px;
    }

    .brandreviews-main {
        padding-bottom: 15%;
    }

    .modal-body-right {
        width: 65%;
    }

}

@media (max-width: 415px) {
    .payment-subsection {
        padding-top: 10px;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 43px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        top: 23px;
    }

    .package-basic {
        left: 6%;
        top: 46.4%;
    }

    .package-premium {
        right: 3%;
        top: 29%;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 79%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.6%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 84%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.7%;
        top: 6%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 84%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.6%;
    }

    .standardmodal .modal-body-right {
        background: rgba(249, 170, 205, 0.3);
        width: 60%;
    }
}

@media (max-width: 413px) {
    .brandreview-p {
        font-size: 11.044px;
    }

    .package-premium {
        right: 2%;
        top: 29%;
    }

    .package-standard {
        left: 33%;
        bottom: 14.2%;
    }

    .package-basic {
        left: 6%;
        top: 46.8%;
    }
}

@media (max-width: 394px) {
    .package-standard {
        left: 33%;
        bottom: 14%;
    }

    .package-premium {
        right: 3%;
        top: 32%;
    }

    .choose-section-video video {
        position: absolute;
        width: 35%;
        right: 42px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        top: 23px;
    }

    .choose-section-video::before {
        content: "";
        position: absolute;
        width: 35%;
        height: 50%;
        bottom: 0px;
        opacity: 0.7;
        background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
        z-index: 9;
        right: 41px;
    }
}

@media (max-width: 391px) {
    .package-basic {
        left: 7%;
        top: 46.5%;
    }

    .package-standard {
        left: 28%;
        bottom: 12%;
    }

    .package-premium {
        right: 3%;
        top: 32.5%;
    }

    .choose-section-video video {
        right: 42px;
        top: 22px;
    }

    .ourwork-main {
        flex-direction: column;
        gap: 40px;
        height: 907px;
    }

    .choose-section-video::before {
        content: "";
        position: absolute;
        width: 35%;
        height: 50%;
        bottom: 0px;
        opacity: 0.7;
        background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
        z-index: 9;
        right: 41px;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 79%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 15.1%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 84%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 15.1%;
        top: 6%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 79%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 15.1%;
    }

    .premiummodal .modal-body-right {
        background: rgba(197, 215, 248, 0.3);
        width: 53%;
    }

    .standardmodal .modal-body-right {
        background: rgba(249, 170, 205, 0.3);
        width: 57%;
    }
}

@media (max-width: 386px) {
    .package-premium {
        right: 2%;
    }

    .choose-section-video video {
        right: 40px;
        top: 21px;
    }

    .brandreview-p {
        font-size: 11.044px;
    }
}

@media (max-width: 381px) {
    .package-premium {
        right: 0%;
    }

    .package-standard {
        left: 29%;
        bottom: 12%;
    }
}

@media (max-width: 376px) {
    .brandreview-p {
        font-size: 10.044px;
    }

    .package-basic {
        left: 5%;
        top: 47.5%;
    }

    .package-premium {
        right: 0%;
        top: 33.9%;
    }

    .package-standard {
        left: 26%;
        bottom: 8.1%;
        z-index: 9;
    }

    .choose-section-video video {
        right: 38px;
        top: 20px;
    }

    .choose-section-video::before {
        right: 39px;
    }

    .standardmodal .modal-body-right {
        background: rgba(249, 170, 205, 0.3);
        width: 50%;
    }

    .premiummodal .modal-body-right {
        background: rgba(197, 215, 248, 0.3);
        width: 49%;
    }

    .premiummodal .modal-body-left {
        width: 71%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 79%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 15.8%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 84%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 15.7%;
        top: 6%;
    }

    .basicmodal .modal-body-left::before {
        left: 15.8%;
    }
}

@media (max-width: 361px) {
    .brandreview-p {
        font-size: 10.044px;
    }

    .identity-form form .formcon .formdata .inputs .css-b62m3t-container {
        width: 30%;
    }

    .packages-section-mob .brands-package {
        height: 717px;
    }

    .brand-header-context h1 {
        font-size: 33.258px;
    }

    .wework-main {
        height: 835px;
    }

    .wework-main::before {
        content: '';
        position: absolute;
        background-image: url(../../images/assets/BrandIdentity/vector-phone.svg);
        width: 105%;
        height: 100%;
        top: 40%;
    }

    .package-basic {
        left: 2%;
        top: 48.8%;
    }

    .package-standard {
        left: 29%;
        bottom: 4.4%;
    }

    .package-premium {
        right: 10%;
        top: 35.2%;
    }

    .choose-section-video::before {
        content: "";
        position: absolute;
        width: 34%;
        height: 50%;
        bottom: 0;
        opacity: 0.7;
        background: linear-gradient(360deg, #079C99 0%, rgba(99, 208, 205, 0.00) 100%);
        z-index: 9;
        right: 39px;
    }

    .choose-section-video video {
        top: 19px;
    }

    .brandsubsection {
        height: 51%;
    }

    .basicmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 81%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 16.5%;
    }

    .standardmodal .modal-body-right {
        background: rgba(249, 170, 205, 0.3);
        width: 45%;
    }

    .standardmodal .modal-body-left {
        padding: 3%;
        padding-top: 8%;
    }

    .premiummodal .modal-body-left {
        width: 80%;
    }

    .premiummodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 75%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 16.5%;
        top: 5%;
    }

    .standardmodal .modal-body-left::before {
        content: '';
        position: absolute;
        height: 85%;
        border-radius: 85.317px;
        background: #0E8581;
        left: 14.1%;
        top: 5%;
        width: 3%;
    }

    .premiummodal .modal-body-right {
        background: rgba(197, 215, 248, 0.3);
        width: 47%;
    }

    .brand-header-div p {
        font-size: 11.52px;
        line-height: 16.512px;
        width: 86%;
    }

    .header-div-img {
        width: 100%;
    }

    .brands-package .payment-subsection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        padding-top: 5%;
    }
}

@media (max-width: 354px) {
    .choose-section-video video {
        right: 36px;
        top: 20px;
    }
}

@media (max-width: 320px) {
    .brands-package .payment-subsection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        padding-top: 25%;
    }

    .packages-section-mob .brands-package {
        height: 780px;
    }

    .package-premium {
        right: 2%;
        top: 34.8%;
    }

    .package-standard {
        left: 33%;
        bottom: 4.4%;
    }

    .choose-section-video {
        justify-content: end;
        padding-right: 5%;
        padding-top: 5%;
    }

    .choose-section-video::before {
        width: 36%;
        right: 20px;
    }

    .choose-section-video video {
        right: 18px;
        width: 37%;
        top: 18px;
    }

    .popup-mob {
        right: 52%;
    }

    .brands-package .subsection-section p,
    .brand-header-div p {
        font-size: 9.52px;
    }

    .brandreview-p {
        font-size: 9.044px;
    }
}

@media (max-width:280px) {}