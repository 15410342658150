@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;800&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Poppins:wght@400;500;800&family=Varela+Round&display=swap');



body {
    padding: 0%;
    box-sizing: border-box;
    font-family: 'Inter';
    justify-content: end;
}

#header {
    width: 100%;
    background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
    position: relative;
    margin-top: -6.5%
}

.homepage-wrapper {
    width: 100%;
    display: flex;
    padding: 0% 7%;
    justify-content: space-between;
    padding-top: 10%;
}

.work-wrapper {
    display: flex;
    padding-top: 10%;
    padding-right: 7%;
    padding-left: 7%;
    justify-content: space-between;
}

.career-wrapper {
    display: flex;
    padding: 10% 7%;
    width: 100%;
    gap: 35%;
}

.bolder {
    font-weight: 500;
}

.chat {
    position: fixed;
    width: 66px;
    height: 66px;
    top: 679px;
    left: 1655px;
    z-index: 1000;
    display: none;
}

.chat:hover {
    width: 76px;
    height: 76px;
}

.chat-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

.chat-wrapper {
    position: relative;
    display: inline-block;
}

.chatbox {
    position: fixed;
    bottom: 255px;
    right: 210px;
    width: 249.452px;
    height: 323px;
    border-radius: 18.387px 18.387px 0px 18.387px;
    background: #FFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.chatbox .chathead {
    background: linear-gradient(rgba(30, 184, 179, 1), rgba(54, 207, 202, 1));
    display: flex;
    padding-left: 10px;
    border-top-left-radius: 18.387px;
    border-top-right-radius: 18.387px;
}

.chathead .helpimg {
    padding: 7px;
    border-radius: 100%;
    background-color: #FFFFFF;
    width: 40px;
    height: 40px;
    display: flex;
    margin-top: 13px;
}

.chathead .chatheadtxt {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 9px;
    margin-right: 58px;
}

.chatbox .chatbody {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    align-items: end;
    padding-right: 15px;
}

.helloplaceholder {
    border-radius: 15.323px 0px 15.323px 15.323px;
    background: #E0E0E0;
    width: 73.548px;
    height: 29.419px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.helloplaceholder h1 {
    color: #8F8F8F;
    font-family: 'Varela Round';
    font-size: 8.581px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.091px;
    text-transform: capitalize;
}

.chatheadtxt h2 {
    color: #FFF;
    font-family: 'Poppins';
    font-size: 11.032px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.333px;
    text-transform: capitalize;
}

.chatheadtxt p {
    color: #FFF;
    font-family: 'Varela Round';
    font-size: 8.581px;
    font-style: normal;
    font-weight: 400;
    line-height: 9.333px;
    text-transform: capitalize;
    margin-top: -4px;
}

.navbar {
    margin: auto;
    padding: 0;
    margin-top: 34px;
    justify-content: space-between;
    width: 100%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: #0E8581;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.navbar-nav .nav-item .nav-link.active {
    border-radius: 100px;
    border: 0.7px solid #17BAB5;
    padding: 5px 8px;

}


@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.navbar-nav .nav-item .nav-link.active {
    animation: slideIn 0.5s linear;
}

.nav_bg {
    z-index: 10;
    padding: 0 5%;
    position: relative;
}

.nav-item {
    margin-right: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.image-logo {
    width: 253px;
    height: 53px;
}

.dropdown-item:hover {
    color: rgb(31, 172, 167);
    background-color: unset;

}

.dropdown-menu {
    background: #F4F4F4 !important;
}

.get-qoute {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 14px 14px 14px;
    color: white;
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: 0.5s ease-out;
    width: 178px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 100px;
    background: linear-gradient(180deg, #201F1F 0%, #000 100%);
    border: none;
    display: flex;
    align-items: center;
    gap: 5px
}

.get-qoute:hover {
    background: linear-gradient(to right, rgba(31, 172, 167, 1) 50%, rgba(14, 133, 129, 1)50%);
    background-position: left;
    background-size: 200% 100%;
}

.modal.show .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
}

.modal-content {
    width: 407px;
    border-radius: 13.844px;
}

.qouteform {
    width: 100%;
    border-radius: 13.844px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 9.690475463867188px 20.765304565429688px 0px rgba(0, 0, 0, 0.10), 0px 38.069725036621094px 38.069725036621094px 0px rgba(0, 0, 0, 0.09), 0px 85.82992553710938px 51.22108459472656px 0px rgba(0, 0, 0, 0.05), 0px 152.27890014648438px 60.91156005859375px 0px rgba(0, 0, 0, 0.01), 0px 237.41664123535156px 66.44898223876953px 0px rgba(0, 0, 0, 0.00);
}

.formtop {
    border-radius: 13.844px 13.844px 0.692px 0.692px;
    background: #D9D9D9;
    height: 40px;
    display: flex;
    justify-content: flex-end;
}

.formimg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 29px;
}

.formimg h1 {
    margin-top: 14.7px;
    color: #201F1F;
    text-align: center;
    font-family: 'Inter';
    font-size: 16.612px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.formimg p {
    margin-top: -6.93px;
    color: #000;
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.getqouteform {
    margin-top: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.qouteformcon {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.qoutedata {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.qoutedata input {
    border-width: 0px;
    background: rgb(0, 0, 0, 0.07);
    width: 300.405px;
    height: 31.148px;
    padding-left: 11.25px;
}

.qoutedata select {
    border-width: 0px;
    background: rgb(0, 0, 0, 0.07);
    width: 58.835px;
    height: 31.148px;
    margin-right: 10px;
    color: #8F8F8F;
    font-family: 'Inter';
    font-size: 11.075px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.228px;
    padding-left: 11.25px;
}

.qoutedata input::placeholder {
    color: #8F8F8F;
    font-family: 'Inter';
    font-size: 11.075px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.228px;
}

.qoutedata .input2 {
    width: 179.943px;
}

.qoutesendbutton {
    margin-top: 44px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: flex;
    width: 159px;
    height: 40.908px;
    padding: 6.175px 12.35px 6.175px 13px;
    justify-content: center;
    align-items: center;
    gap: 7.718px;
    border-width: 0px;
    border-radius: 6.175px;
    background: linear-gradient(270deg, #19AFAA 0%, #3CD6D0 100%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 0.771844744682312px 0px rgba(0, 0, 0, 0.10), 0px 1.543689489364624px 1.543689489364624px 0px rgba(0, 0, 0, 0.09), 0px 3.087378978729248px 2.3155341148376465px 0px rgba(0, 0, 0, 0.05), 0px 6.174757957458496px 2.3155341148376465px 0px rgba(0, 0, 0, 0.01), 0px 9.262136459350586px 2.3155341148376465px 0px rgba(0, 0, 0, 0.00);
    color: black;
    text-align: center;
    font-size: 16.292px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content h1 {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 57px;
    align-items: center;
    text-transform: capitalize;
}

.content h1 .firstline {
    background: linear-gradient(271deg, #048985 26.35%, #35CDC8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* or 167% */

    display: flex;
    align-items: flex-end;
    text-transform: capitalize;

    color: #696969;
    border-radius: null;
    position: relative;


}

.btn-more-info {
    max-width: 159px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: black;
    display: flex;
    padding: 6.2px 12.3495px 6.2px 13px;
    align-items: center;

    background: linear-gradient(270deg, #19AFAA 0%, #3CD6D0 108.05%);
    box-shadow: 0px 6.17476px 2.31553px rgba(0, 0, 0, 0.01), 0px 3.08738px 2.31553px rgba(0, 0, 0, 0.05), 0px 1.54369px 1.54369px rgba(0, 0, 0, 0.09), 0px 0px 0.771845px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border-width: 0px;
}

.btn-more-info:hover {
    color: white;
    background: black;
}

.homepage-wrapper .content a {
    text-decoration: none;
    color: black;
}

.homepage-wrapper .content a:hover {
    color: white;
}

.moreinfoarrow {
    animation: scrollText 0.8s infinite linear;
    stroke: #bec1ce;
}

@keyframes scrollText {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-20%);
    }
}

.arrowblack {
    object-fit: contain;
    padding-left: 8px;
}

.arrowwhite {
    object-fit: contain;
}

.header-img-cube {
    width: 354px;
    height: 492px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.cube-image {
    animation: up-down 2s ease-in-out infinite;
}

@keyframes up-down {

    0%,
    100% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-10px);
    }


}

.shadow-img {
    animation: scale-up 2s ease-in-out infinite;
}

@keyframes scale-up {

    0%,
    100% {
        scale: 1.2;
    }

    50% {
        scale: 1;
    }
}


.selection {
    position: absolute;
    display: flex;
    bottom: 20px;
    left: 50%;
    column-gap: 6px;
}

.ellipse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(23, 186, 181, 0.3);
    margin: 2px;
    cursor: pointer;

}

.selected {
    background: #17BAB5;
    display: flex;

}

.Agency-tag {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Agency-tag h2 {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #201F1F;
}

.agency {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
    padding-top: 115px;
}

.Agency-tag h3 {
    max-width: 825px;
    margin-top: 43px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    text-align: center;
    text-transform: capitalize;
    color: #696969;
}

.cards {
    justify-content: center;
    column-gap: 16px;
    margin-top: 72px;
    padding-bottom: 5%;
}

.grp {
    display: flex;
    margin-bottom: 17px;
}

.group {
    width: 203px;
    height: 235px;
    margin-top: 13px;
    border-radius: 6.26px;
    padding-left: 17px;
    padding-right: 11px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: ease-in 100ms;
    background: #FFFFFF;
    filter: drop-shadow(0px 41.7137px 16.6855px rgba(0, 0, 0, 0.01)) drop-shadow(0px 22.9425px 13.557px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10.4284px 10.4284px rgba(0, 0, 0, 0.09)) drop-shadow(0px 2.08569px 5.21421px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}

.group:hover {
    background: linear-gradient(180deg, #3CD6D0 0%, #0E8581 100%);
    width: 203px;
    height: 258px;
    margin-top: -13px;
    border-radius: 6.26px;
    padding-left: 17px;
    filter: drop-shadow(0px 41.7137px 16.6855px rgba(0, 0, 0, 0.01)) drop-shadow(0px 22.9425px 13.557px rgba(0, 0, 0, 0.05)) drop-shadow(0px 10.4284px 10.4284px rgba(0, 0, 0, 0.09)) drop-shadow(0px 2.08569px 5.21421px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}

.group:hover .heading {
    color: #FFFFFF !important;
}

.group:hover h4 {
    color: #FFFFFF;
}

.group:hover .View-more {
    color: #FFFFFF;
}

.group:hover .vector {
    display: none;
}

.heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 31px;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #201F1F;
}

.iconn {
    padding: 12px;
    border-radius: 100%;
    background-color: #FFFFFF;
    width: 58px;
    height: 58px;
    display: flex;
    margin-top: 13px;
}

.vector {
    object-fit: contain;
    padding-left: 7px;
}

.vectorwhite {
    object-fit: contain;
    padding-left: 7px;
}

.View-more {
    margin-top: 3px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    font-size: 11.056px;
    line-height: 31px;

    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #1FACA7;

}

h4 {
    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10.365px;
    line-height: 18px;

    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #696969;

}

.advise {
    width: 100%;
    height: 616px;
    background: linear-gradient(180deg, #E2F6F6 0%, #17BAB5 100%);
    top: 1603px;
    justify-content: center;
}

.pwr {
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;

    color: #201F1F;
}

.about-wrapper {
    display: flex;
    padding-top: 10%;
    padding-bottom: 5%;
    padding-right: 7%;
    padding-left: 7%;
    justify-content: space-between;
}

.grp1 {
    position: relative;
    z-index: -10;
    scale: 0.9;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.powering {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
}

.powering .powering-tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.powering .cards {
    background-color: #0E8581;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    height: 400px;
    align-items: center;
    padding: 20px 10px;

    position: relative;
}

.powering .card {
    background-color: #201F1F;
    width: 200px;
    height: 200px;
}

.powering .bgg {
    position: absolute;
    left: 0px;
    object-fit: cover;

    width: 100%;
    height: 100%;
}

.powering .requests {
    display: flex;
    justify-content: center;
    padding: 40px;
    column-gap: 20px;

}

.powering-tag h1 {
    margin-top: 56px;
    font-size: 24px;
    font-family: 'Varela Round';
    letter-spacing: -0.48px;
    line-height: 30px;
}

.powering-tag p {
    margin-top: 9px;
    color: #696969;
    font-size: 15px;
    font-family: Poppins;
    line-height: 21.5px;
    max-width: 825px;
}

.cards .data {
    width: 240px;
    height: 202px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cards .data h1 {
    margin-top: 36px;
    color: #FFFFFF;
    font-size: 55px;
    font-family: 'Poppins';
    font-weight: 800;
    line-height: 30px;
    letter-spacing: -1.1px;
}

.cards .data p {
    margin-top: 36px;
    color: white;
    font-size: 14px;
    font-family: 'Poppins';
}

.cards .data .dataimg {
    width: 70px;
    height: 60px;
}

.request {
    justify-content: center;
    display: flex;
    margin-top: 74px;
    padding-bottom: 20px;
}

.request button {
    margin-right: 14px;
    width: 211px;
    border-radius: 100px;
    height: 37px;
    gap: 10px;
    border: 0.5px solid #0E8581;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Varela Round';
    line-height: 21.5px;
    letter-spacing: -0.28px;
    align-items: center;
    background: linear-gradient(180deg, #1FACA7 0%, #0E8581 100%);
    color: #FFFFFF;
}

.request button:hover {
    background: black;
    border: black
}

.reqimg {
    padding-left: 10px;
}

.clients {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 550px;
    background: rgba(0, 0, 0, 0.04)
}

.home-logos {
    overflow: hidden;
    width: 100%;
}

.home-logo-section {
    display: flex;
    gap: 4%;
    animation: leftmovement 20s linear infinite;
}

@keyframes leftmovement {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

#logos .clientscon h1 {
    margin-top: 136px;
    font-size: 24px;
    font-family: 'Varela Round';
    letter-spacing: -0.48px;
    line-height: 30px;
    color: #201F1F;
}

#logos .clientscon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#logos .clientscon h2 {
    margin-top: 9px;
    font-family: 'Poppins';
    font-size: 16.95px;
    line-height: 21.5px;
    text-transform: capitalize;
    color: #696969;
}

#logos {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    background: rgb(0, 0, 0, 0.07);
    padding-bottom: 10%;
}

#logos .box {
    justify-content: center;
    width: 140px;
    height: 112px;
    display: flex !important;
}

.slick-dots {
    display: none !important;
}

.slick-slide div {
    margin: 0.2rem;
}

.slick-prev:before,
.slick-next:before {
    color: rgba(60, 214, 208, 1) !important;
    font-size: 30px !important;
}

.slick-prev {
    left: -35px;
}

.logos {
    margin-top: 81px;
    display: flex;
    flex-direction: row;
    gap: 34px;
    align-items: center;
}

.techlogos {
    display: flex;
    flex-direction: row;
}


.FAQs {
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    align-items: center;
}

.FAQs .faqtxt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.faqtxt h1 {
    color: #201F1F;
    text-align: center;
    font-family: 'Varela Round';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
}

.faqtxt p {
    color: #696969;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16.95px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5px;
    text-transform: capitalize;
}

.FAQs .questions {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 125px;

}

.FAQs .FAQ1 {
    display: flex;
    flex-direction: column;
    background: white;
    width: 889px;
    padding: 25px;
    border-radius: 16px;
    justify-content: space-between;
    margin-bottom: 12px;
}

.FAQs .FAQ1a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.FAQ1 h2 {
    color: #17BAB5;
    font-family: 'Varela Round';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
}

.FAQ1b p {
    color: #696969;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
}

.FAQ1 button {
    background: none !important;
    border-width: 0px;
}

.FAQ1b {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

}

.FAQ1b img {
    width: 100%;
}

.FAQ1b.visible {
    display: block;
    opacity: 1;
}


.FAQ1a .faqButton img {
    transition: transform 0.3s ease-in-out;
}

.FAQ1a .faqButton.rotated img {
    transform: rotate(180deg);
}

.FAQ1a h2 {
    transition: transform color 0.3s ease-in-out;
}

.grayscale {
    filter: grayscale(100%);
}

.techlogo {
    opacity: 35%;
    filter: grayscale(100%);
}

.techlogo:hover {
    opacity: 100%;
    filter: grayscale(0%);
}

.review {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    padding-bottom: 250px;
}

.carousel {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    padding-bottom: 10%;
}

.reviewcon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box {
    justify-content: center;
    height: 366.782px;
    display: flex !important;
}

.slick-dots {
    display: none !important;
}

.slick-slide div {
    margin: 0.2rem;
}

.slick-prev:before,
.slick-next:before {
    color: rgba(60, 214, 208, 1) !important;
    font-size: 30px !important;
}

.reviewcon h1 {
    margin-top: 118px;
    font-size: 24px;
    font-family: 'Varela Round';
    letter-spacing: -0.48px;
    line-height: 30px;
    color: #201F1F;
}

.reviewcon h2 {
    font-family: 'Poppins';
    font-size: 16.95px;
    line-height: 14.5px;
    text-transform: capitalize;
    color: #696969;
}

.reviews {
    display: flex;
    flex-direction: row;

}

.revcards {
    width: 350.129px;
    flex-shrink: 0;
    border-radius: 20.792px;
    background-color: #FFFFFF;
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 68px !important
}

.revcards:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 21px 13px 0px rgba(0, 0, 0, 0.05), 0px 37px 15px 0px rgba(0, 0, 0, 0.01), 0px 58px 16px 0px rgba(0, 0, 0, 0.00);
}

.revtext {
    width: 290px;
    color: #000;
    text-align: center;
    font-size: 12.475px;
    font-family: 'Varela Round';
    line-height: 18.297px;
    text-transform: capitalize;
    margin-top: 14.14px;
}

.info h1 {
    margin-top: 15.8px;
    font-size: 14.97px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 21.623px;
}

.info p {
    margin-top: -10px;
    color: #606060;
    text-align: center;
    font-size: 12.475px;
    font-family: 'Inter';
    line-height: 21.623px;
    text-transform: capitalize;
}

.calouser .stars {
    margin-top: 24.39px;
}

.revcards .face {
    width: 93px;
    height: 93px;
}


.footerMain {

    background: #201F1F;
    flex-direction: column;
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    background-image: url(../src/images/assets/Rectangle.png);
    background-blend-mode: color-dodge;
    background-repeat: no-repeat;
}

.footerMain a {
    color: white;
    text-decoration: none;
}

.footerMain .footer {
    width: 100%;
    display: flex;
    margin-top: 65px;
    justify-content: space-between;
}

.footerTxt {
    display: flex;
    margin-left: 75px;
    gap: 160px;
}

.footerTxt p {
    color: #FFF;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px;
    text-transform: capitalize;
}

.footerInfo {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-right: 75px;
}

.footer h1 {
    color: white;
    font-size: 18px;
    font-family: 'Inter';
    line-height: 39px;

}

.footer h2 {
    font-size: 15.445px;
    color: white;
    font-family: 'Inter';
    line-height: 25.742px;
}

.endTxt {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.endTxt h3 {
    margin-left: 75px;
    margin-right: 75px;
    color: #FFFFFF;
    font-size: 15.445px;
    font-family: 'Inter';
    line-height: 25.742px;
}

.aboutceo1 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15)),
        url(../src/images/assets/Rectangle.png);
    background-blend-mode: color-dodge;
    background-repeat: no-repeat;
    background-position: left;
    display: flex;
    justify-content: center;
}

.aboutceo2 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15)),
        url(../src/images/assets/Rectangleflipped.png);
    background-blend-mode: color-dodge;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    justify-content: center;
}

.ceomain {
    display: flex;
    gap: 54px;
    align-items: center;
}

.ceomaintxt {
    margin-top: 139px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 646px;
    margin-bottom: 94px;
}

.ceomaintxt h1 {
    font-family: 'Inter';
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    text-transform: capitalize;
    background: linear-gradient(271deg, #048985 26.35%, #35CDC8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ceomaintxt h2 {
    color: #696969;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-transform: capitalize;
}

.ceomaintxt p {
    color: #696969;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    /* 143.333% */
    text-transform: capitalize;
}


.aboutcon {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 125px;
}

.aboutcontxt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.aboutdata {
    display: flex;
    margin-top: 135px;
    column-gap: 10px;
}

.aboutdata .dots {
    display: flex;
    flex-direction: column;
    gap: 120px;
    align-items: center;
}

.dots h1 {
    color: #17BAB5;
    font-size: 24px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 31.439px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.dots p {
    transform: rotate(90deg);
}

.aboutcontxt h1 {
    font-family: 'Varela Round';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 115px;
}

.aboutcontxt h3 {
    text-align: center;
    color: #696969;
    font-size: 15px;
    width: 825px;
    font-family: 'Poppins';
    line-height: 21.5px;
    margin-top: 20px;
}

.aboutdata .comps {
    width: 327px;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.aboutcon .aboutcards {
    width: 327px;
    height: 217px;
    margin-top: 23px;
    background: #F4F4F4;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 23px;
}

.comps .aboutcards h1 {
    color: #3B3E3E;
    text-align: center;
    font-size: 18.651px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 35.358px;
    letter-spacing: -0.373px;
}

.comps .aboutcards p {
    color: #696969;
    text-align: center;
    font-size: 11.044px;
    font-family: 'Poppins';
    line-height: 20.036px;
    width: 280px;
}

.comps .aboutimg {
    border-radius: 17px;
    margin-top: 23px;
}

.brand {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 151px;
}

.brand h3 {
    text-align: center;
    color: #696969;
    font-size: 15px;
    width: 825px;
    font-family: 'Poppins';
    line-height: 21.5px;
    margin-top: 20px;
}

.brand h1 {
    font-family: 'Varela Round';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 115px;
}

#work {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    background: linear-gradient(to bottom, rgba(23, 186, 181, 1) 0%, rgba(14, 133, 129, 1)424px, 50%, rgba(0, 0, 0, 0.03)50%);
    padding-bottom: 10%;
}

#work .slick-dots {
    display: block !important;
}

#work .slick-dots li button:before {
    font-size: 12px !important;
    color: rgba(23, 186, 181, 1) !important;
}

.slick-slide div {
    margin: 0.2rem;
}

#work .slick-prev:before,
#work .slick-next:before {
    color: rgba(60, 214, 208, 1) !important;
    font-size: 30px !important;
    display: none !important;
}

#work .work1txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#work .work1txt h1 {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 99px;
}

#work .work1txt p {
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    line-height: 21.5px;
    text-transform: capitalize;
    width: 808px;
}

#work .work1card {
    display: flex;
    flex-direction: row;
    margin-top: 59px;
    gap: 35px;
    width: 1008px;
    overflow: hidden;
}

#work .workcard {
    background-color: white;
    border-radius: 6.325px;
    width: 302px;
    padding-top: 15px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6.325px;
    background: #FFF;
}

#work h2 {
    color: #24A9A5;
    text-align: center;
    font-size: 18.976px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 23.72px;
    background: white;
    display: flex;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 36.371px;
    border: 1.581px solid #23AAA6;
    padding: 6px;
    margin-bottom: -15px;
    z-index: 1;

}

#work .h1Card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#work .workimg {
    height: 115px;
}

#work .button {

    width: 302px;
    margin-top: 25px;
    border-radius: 0px 0px 6.33px 6.33px;
    background: linear-gradient(rgba(31, 172, 167, 1), rgba(14, 133, 129, 1));
    height: 42px;
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: 800;
    font-family: 'Inter';
    text-transform: uppercase;
    border: none;
}

#work .button:hover {
    background: #F0F0F0;
    color: #0E8581;
}

#work .workcardtxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#work .wordkcardtxt .workstars {
    margin-top: 10px;
}

#work .workcardtxt p {
    color: #686868;
    text-align: center;
    font-size: 9px;
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    line-height: 10.788px;
    margin-top: 9px;
    margin-bottom: 11px;
}



.work2 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
}

.work2 .worktxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.worktxt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 52px;
}

.worktxt p {
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    line-height: 21.5px;
    text-transform: capitalize;
    margin-top: 20px;
    width: 100%;
    max-width: 825px;
}

.workpro {
    display: flex;
    flex-direction: row;
    gap: 13px;
}

.workpro .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 52px;
    object-fit: contain;
}

.profile .picture {
    height: 179px;
    width: 302px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.profile button {
    width: 302px;
    border-radius: 0px 0px 6.33px 6.33px;
    background: linear-gradient(rgba(31, 172, 167, 1), rgba(14, 133, 129, 1));
    height: 42px;
    color: white;
    text-align: center;
    font-size: 11.056px;
    font-family: 'Varela Round';
    line-height: 31.439px;
    letter-spacing: -0.221px;
    text-transform: capitalize;
    border: none;
}

.profile button:hover {
    background: linear-gradient(rgba(72, 72, 72, 1), rgba(38, 38, 38, 1));
}

.work3 {
    background: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
}

.work3 .worktxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work4 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
}

.work4 .worktxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work5 {
    background: linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
}

.work5 .worktxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.work6 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 150px;
}

.work6 .worktxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#header2 {
    width: 100%;
    background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
    position: relative;
    margin-top: -6.5%
}

.career1 {
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(74, 74, 74, 0.15));
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career1txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career1txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 132px;
}

.career1txt p {
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
    width: 762px;
    margin-bottom: 58px;
}

.career1con {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 36px;
    margin-bottom: 140px;
}

.career1con .con1 {
    width: 386px;
    height: 253px;
    border-radius: 21.225px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 6.17476px 2.31553px rgba(0, 0, 0, 0.01), 0px 3.08738px 2.31553px rgba(0, 0, 0, 0.05), 0px 1.54369px 1.54369px rgba(0, 0, 0, 0.09), 0px 0px 0.771845px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.career1con .con1 h2 {
    color: #3B3E3E;
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 41.707px;
    letter-spacing: -0.44px;
    text-transform: capitalize;
    margin-top: 21px;
    margin-bottom: 14px;
}

.career1con .con1 p {
    color: #696969;
    text-align: center;
    font-size: 13.027px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 23.634px;
    text-transform: capitalize;
    width: 330px;
}

.career1con .con2img {
    border-radius: 21.225px;
    height: 253px;
}

.career2 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career2txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career2txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 132px;
}

.career2txt p {
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
    width: 762px;
    margin-bottom: 58px;
}

.career2con {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 36px;
    margin-bottom: 140px;
}

.career2con .con2 {
    width: 386px;
    height: 253px;
    border-radius: 21.225px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 6.17476px 2.31553px rgba(0, 0, 0, 0.01), 0px 3.08738px 2.31553px rgba(0, 0, 0, 0.05), 0px 1.54369px 1.54369px rgba(0, 0, 0, 0.09), 0px 0px 0.771845px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.career2con .con2 h2 {
    color: #3B3E3E;
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 41.707px;
    letter-spacing: -0.44px;
    text-transform: capitalize;
    margin-top: 21px;
    margin-bottom: 14px;
}

.career2con .con2 p {
    color: #696969;
    text-align: center;
    font-size: 13.027px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 23.634px;
    text-transform: capitalize;
    width: 330px;
}

.career1con .con1img {
    border-radius: 21.225px;
    height: 253px;
}

.career3 {
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(74, 74, 74, 0.15));
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career3txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career3txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 132px;
}

.career3txt p {
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
    width: 762px;
    margin-bottom: 58px;
}

.career3con {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 36px;
    margin-bottom: 140px;
}

.career3con .con1 {
    width: 386px;
    height: 253px;
    border-radius: 21.225px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 6.17476px 2.31553px rgba(0, 0, 0, 0.01), 0px 3.08738px 2.31553px rgba(0, 0, 0, 0.05), 0px 1.54369px 1.54369px rgba(0, 0, 0, 0.09), 0px 0px 0.771845px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.career3con .con1 h2 {
    color: #3B3E3E;
    text-align: center;
    font-size: 22px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 41.707px;
    letter-spacing: -0.44px;
    text-transform: capitalize;
    margin-top: 21px;
    margin-bottom: 14px;
}

.career3con .con1 p {
    color: #696969;
    text-align: center;
    font-size: 13.027px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 23.634px;
    text-transform: capitalize;
    width: 330px;
}

.career3con .con2img {
    border-radius: 21.225px;
    height: 253px;
}

.career4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
}

.career4txt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.career4txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 83px;
}

.career4txt p {
    width: 895px;
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
}

.career4 .joinus button {
    margin-right: 14px;
    width: 128px;
    border-radius: 100px;
    height: 37px;
    gap: 10px;
    border: 0.5px solid #0E8581;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: 'Varela Round';
    line-height: 21.5px;
    letter-spacing: -0.28px;
    align-items: center;
    background: linear-gradient(180deg, #1FACA7 0%, #0E8581 100%);
    color: #FFFFFF;
    margin-top: 21px;
    margin-bottom: 72px;
}

.service1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
}

.service2 {
    background: rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 72px;
}

.service2txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
}

.service2txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.service2txt p {
    width: 895px;
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
    margin-bottom: 60px;
}

.service2con {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.condiv {
    display: flex;
    flex-direction: column;
    gap: 34px;
}

.subdiv {
    width: 291px;
    height: 236px;
    background: rgba(217, 217, 217, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 37px;
}

.subdiv h1 {
    color: #201F1F;
    font-size: 16.584px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 31.439px;
    letter-spacing: -0.332px;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 10px;
}

.subdiv p {
    width: 249px;
    color: #696969;
    text-align: center;
    font-size: 10.365px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 17.816px;
    text-transform: capitalize;
}

.service3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15));
    padding-bottom: 108px;
}

.service3txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
}

.service3txt h1 {
    color: #201F1F;
    text-align: center;
    font-size: 24px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.service3txt p {
    width: 895px;
    color: #696969;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
}

.contact1 {
    background-image: url(../src/images/assets/Ellipse20.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -92px;

}

@keyframes formAnimation {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.contact1form {
    animation: formAnimation 1s ease-in-out forwards;
}

.contact1txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}

.contact1txt h1 {
    color: #464646;
    text-align: center;
    font-size: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
}

.contact1txt p {
    color: #464646;
    text-align: center;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 15.5px;
    text-transform: capitalize;
}

.contact1 .form {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 15px;
    width: 743.113px;
    padding: 25px 76px 78.553px 76px;
    justify-content: center;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 20px 12px 0px rgba(0, 0, 0, 0.05), 0px 36px 14px 0px rgba(0, 0, 0, 0.01), 0px 56px 16px 0px rgba(0, 0, 0, 0.00);
    ;
}

.form .formcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
    width: 95%;
    /* height: 57px; */
}

.form .formdata {
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
}

.form label {
    color: #575757;
    font-size: 13.237px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15.506px;
    text-transform: capitalize;
}

.form input {
    border-radius: 3.524px;
    background: rgb(0, 0, 0, 0.07);
    border-width: 0px;
    width: 278px;
    height: 32px;
    color: #ACACAC;
    font-size: 11.277px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15.506px;
    padding-left: 16px;

}

.form .name {
    background-image: url(../src/images/assets/username.svg);
    background-repeat: no-repeat;
    display: flex;
    background-position: 8px;
    padding-left: 28px;
}

.form .email {
    background-image: url(../src/images/assets/mail.svg);
    background-repeat: no-repeat;
    display: flex;
    background-position: 8px;
    padding-left: 28px;
}

.form .servicedrop {
    background-image: url(../src/images/assets/service.svg);
    background-repeat: no-repeat;
    display: flex;
    background-position: 8px;
    padding-left: 28px;
}

.form select {
    border-radius: 3.524px;
    background: rgb(0, 0, 0, 0.07);
    border-width: 0px;
    width: 278px;
    height: 32px;
    color: #ACACAC;
    font-size: 11.277px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15.506px;
    text-transform: capitalize;
}

select .options {
    margin-bottom: 5px !important;
}

.formcon .inputs {
    display: flex;
    width: 279px;
    gap: 5px;

}

.formcon .codes {
    width: 58.835px;
    height: 32px;
}

.formcon .input2 {
    width: 177px;
}

.formcon2 input {
    border-radius: 3.524px;
    background: rgb(0, 0, 0, 0.07);
    border-width: 0px;
    width: 591px;
    height: 99px;
    padding-left: 8px;
    line-height: 1;
    word-wrap: break-word;
    text-align: left;
}

.formcon2 input::placeholder {
    position: absolute;
    top: 7px;
    left: 7px;
}

.form .text {
    color: #828282;
    font-size: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15.506px;
    border-radius: 3.524px;
    background: rgb(0, 0, 0, 0.07);
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
}

.text .checkbox {
    width: 18px;
    height: 15px;
    border-radius: 3px;
    border: 0.509px solid #17BAB5;
    background: #FFF;
}

.sendbutton {
    display: flex;
    width: 86px;
    height: 27px;
    padding: 6.287px 12.575px 6.287px 13.237px;
    justify-content: center;
    align-items: center;
    gap: 7.859px;
    border-width: 0px;
    border-radius: 7px;
    background: linear-gradient(270deg, #36CFC9 0%, #1CB3AE 100%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 0px 0.7859299182891846px 0px rgba(0, 0, 0, 0.10), 0px 1.5718598365783691px 1.5718598365783691px 0px rgba(0, 0, 0, 0.09), 0px 3.1437196731567383px 2.3577897548675537px 0px rgba(0, 0, 0, 0.05), 0px 6.287439346313477px 2.3577897548675537px 0px rgba(0, 0, 0, 0.01), 0px 9.431159019470215px 2.3577897548675537px 0px rgba(0, 0, 0, 0.00);
    color: #FFF;
    text-align: center;
    font-size: 16.292px;
    font-family: 'Varela Round';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.thankyou {
    background-image: url(../src/images/assets/Ellipse20.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 490px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -92px;
    margin-bottom: 300px;
}

.thankyou .thankyoutxt {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    width: 50%;
    height: fit-content;
    padding: 74px 102px 73px 103px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.10), 0px 9px 9px 0px rgba(0, 0, 0, 0.09), 0px 20px 12px 0px rgba(0, 0, 0, 0.05), 0px 36px 14px 0px rgba(0, 0, 0, 0.01), 0px 56px 16px 0px rgba(0, 0, 0, 0.00);
}

.thankyoutxt h1 {
    color: #464646;
    text-align: center;
    font-size: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
}

.thankyoutxt p {
    color: #575757;
    text-align: center;
    font-size: 13.237px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 19.006px;
    text-transform: capitalize;
}

.contact2 {
    display: flex;
    flex-direction: row;
    gap: 27px;
    align-items: center;
    justify-content: center;
    margin-top: 520px;
    margin-bottom: 220px;
}

.contact2 .contactus {
    width: 347px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactus .image {
    background: rgba(23, 186, 181, 0.1);
    border-radius: 65px;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
}

.contactus h2 {
    color: #464646;
    text-align: center;
    font-size: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    margin-top: 33px;
}

.contactus h3 {
    color: #696969;
    width: 217px;
    text-align: center;
    font-size: 10.36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-transform: capitalize;
    margin-top: 12px;
    margin-bottom: 12px;
}

.contactus p a {
    color: #17BAB5;
    text-align: center;
    font-size: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: 21.5px;
}

.blog1 {
    background: linear-gradient(rgba(226, 246, 246, 0.14), rgba(23, 186, 181, 0.14));
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -92px;
}

.blog1txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 297px;
    gap: 21px;

}

.blog1txt h1 {
    font-family: 'Inter';
    font-size: 52.822px;
    font-style: normal;
    font-weight: 800;
    line-height: 109.523%;
    /* 57.853px */
    text-transform: capitalize;
    background: linear-gradient(271deg, #048985 26.35%, #35CDC8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blog1txt p {
    width: 597px;
    color: #696969;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    text-transform: capitalize;
}

.blog1 .searchbar {

    display: flex;
    justify-content: center;
    margin-bottom: 13%;
    padding-top: 2%;
}

.searchbar input {
    width: 30%;
    height: 48px;
    border-radius: 50px;
    border-width: 0;
    padding-left: 4%;

    background-image: url(./images/assets/searchnar.png);
    background-repeat: no-repeat;
}

.blog2 {
    background: linear-gradient(rgba(226, 246, 246, 0.15), rgba(23, 186, 181, 0.15)), url(../src/images/assets/Rectangle.png);
    background-blend-mode: color-dodge;
    background-repeat: no-repeat;
}

.object-list {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 43px;
    padding-left: 190px;
    padding-top: 91px;
    row-gap: 100px;
}

.object-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: 300px;
}

.object-item button {
    border-radius: 3px;
    background: #2C3232;
    display: flex;
    width: 113.174px;
    height: 31px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 160% */
    text-transform: capitalize;
    border-width: 0;

}

.buttondiv {
    margin-top: 182px;
    padding-bottom: 88px;
    display: flex;
    justify-content: center;
}

.object-item button:hover {
    background: rgba(23, 186, 181, 1);
}


.object-item img {
    width: 100%;
    height: 195px;
    border-radius: 21.23px;
    background: #D9D9D9;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 45px 13px 0px rgba(0, 0, 0, 0.00);
}

.object-item h3 {
    margin: 10px 0;
    color: #201F1F;
    font-family: 'Varela Round';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    letter-spacing: -0.4px;
    text-transform: capitalize;

}

.description {
    max-height: 3em;
    overflow: hidden;
    line-height: 1.5em;
    text-overflow: ellipsis;
    margin-bottom: 23px;
    color: #696969;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    /* 143.333% */
    text-transform: capitalize;
}

.showmore {

    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 100px;
    border: 0.5px solid #0E8581;
    background: linear-gradient(180deg, #1FACA7 0%, #0E8581 100%);
    width: 149px;
    height: 37px;
    color: white;
    text-align: center;
    font-family: 'Varela Round';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    /* 153.571% */
    letter-spacing: -0.28px;
    text-transform: capitalize;
    justify-content: center;
}

.showmore:hover {
    background: #000;
    border: 0.5px solid black;
}

.showmore img {
    transition: transform 0.3s ease;
    /* Add a smooth transition */
}

.rotate-arrow img {
    transform: rotate(180deg);
}

.css-1nmdiq5-menu {
    width: 260px !important;
}

.css-b62m3t-container {
    padding-bottom: 3%;
    width: 35%;
    height: 32px;
    min-height: 32px !important;
}

.css-t3ipsp-control {
    min-height: 32px !important;
    height: 32px !important;
    border-width: 0px !important;
    background: #ededed !important;
    border-radius: 3.524px !important;
    width: 90%;
}

.css-t3ipsp-control {
    min-height: 32px !important;
    height: 32px !important;
    border-width: 0px !important;
    background: #ededed !important;
    border-radius: 3.524px !important;
    width: 100%;
}

.css-t3ipsp-control .css-1jqq78o-placeholder {
    padding: 6px;
}

.css-t3ipsp-control .css-1cfo1cf {
    padding-bottom: 10px !important;
    padding-top: 0px !important;
    margin: 0px !important;
}

.css-13cymwt-control {
    min-height: 32px !important;
    height: 32px !important;
    border-width: 0px !important;
    background: #ededed !important;
    border-radius: 3.524px !important;
    width: 100%;
}

.qoutedata .css-13cymwt-control {
    min-height: 32px !important;
    height: 32px !important;
    border-width: 0px !important;
    background: #ededed !important;
    border-radius: 3.524px !important;
    width: 85%;
}

.css-13cymwt-control .css-1jqq78o-placeholder {
    display: flex;
    padding-left: 6px;
    padding-bottom: 2px;
    align-items: baseline;
}

.css-qbdosj-Input:after {
    min-width: 5px !important;
}

.css-t3ipsp-control .css-1fdsijx-ValueContainer {
    padding: 2px 0px !important;
    border-radius: 3.524px !important;
    width: 140px;
}

.qoutedata .css-t3ipsp-control .css-1fdsijx-ValueContainer {
    padding: 2px 0px !important;
    border-radius: 3.524px !important;
    width: 117px;
}

.qoutedata .css-13cymwt-control .css-1fdsijx-ValueContainer {
    background: #ededed;
    border-radius: 3.524px !important;
    min-height: 32px !important;
    height: 32px;
    width: 127px;
    padding: 2px 0px !important;
}

.css-13cymwt-control .css-1fdsijx-ValueContainer {
    background: #ededed;
    border-radius: 3.524px !important;
    min-height: 32px !important;
    height: 32px;
    width: 120px;
    padding: 2px 0px !important;
}


.css-1hb7zxy-IndicatorsContainer {
    background: #ededed;
    border-radius: 3.524px !important;
    min-height: 32px !important;
    height: 32px;
}

.css-qbdosj-Input {
    font-size: 11.075px;
    padding: 0px 0px !important;
    height: 32px;
    margin-bottom: -2px !important;
    margin: 0 !important;
}

.css-19bb58m {
    margin: 0 !important;
    padding-top: 0 !important;
}

.css-hlgwow {
    min-height: 32px !important;
    height: 32px !important;
    width: 65px;
    padding: 0px !important;
}

.css-166bipr-Input {
    display: flex !important;
    align-self: start !important;
    margin: 0 !important;
    padding-top: 0 !important;
}

.css-1dimb5e-singleValue {
    font-size: 10px;
    margin-left: 9px !important;
    margin-bottom: 3px !important;
}

.error {
    color: red;
    font-size: 8px;
}


.icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 4%;
    padding-right: 12%;
}

.dash-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 1% 0;
}

.mob-cards {
    display: none;
}

.aboutdata-mob {
    display: none;
}

.footerTxt-mob {
    display: none
}

.navbar-toggler {
    background: #17BAB5;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.10)) drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.09)) drop-shadow(0px 13px 8px rgba(0, 0, 0, 0.05)) drop-shadow(0px 24px 9px rgba(0, 0, 0, 0.01)) drop-shadow(0px 37px 10px rgba(0, 0, 0, 0.00));
}

.navbar-toggler-icon {
    color: white;
}

.imgs {
    display: flex;
    gap: 10px;
}

.loader {
    position: absolute;
    width: 100%;
    height: 105vh;
    background: rgb(0, 0, 0, 1);
    z-index: 99999;
    margin-top: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader .stroke,
.loader .filled {
    position: absolute;
    display: flex;
    width: 30%;
}

.loader .stroke {
    z-index: 2;
}

.loader-bg {
    position: absolute;
    width: 32%;
    height: 80%;
    z-index: 1;
    top: 0;
    background: rgb(0, 0, 0);
    animation: loader 15s ease-in-out;
}


.carousel .slick-prev::before {
    display: none;
}

.carousel .slick-dots {
    display: block !important;
}

.carousel .slick-dots li button:before {
    color: #17BAB5;
    font-size: 20px;
}

.footerInfo-mob {
    display: none;
}

.icons-mob {
    display: none;
}

.endTxt-mob {
    display: none;
}

@keyframes loader {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-80%);
    }
}

@media (max-width:1681px) {
    .footerTxt {
        gap: 160px;
    }

    .icons {
        padding-right: 14%;
    }
}

@media (max-width: 1601px) {
    .lower {
        right: 0;
        bottom: -23%;
    }

    .icons {
        padding-right: 14.5%;
    }
}

@media (max-width: 1585px) {
    .loader {
        margin-top: -7%;
    }

    .qoutedata .input2 {
        width: 203.943px;
    }

    .icons {
        padding-right: 15%;
    }
}

@media (max-width: 1441px) {
    .career-wrapper {
        gap: 22%;
    }

    .icons {
        padding-right: 16%;
    }
}

@media (max-width: 1367px) {
    .footerTxt {
        gap: 150px;
    }

    .career-wrapper {
        gap: 18%;
    }

    .work-wrapper {
        padding-right: 0%;
    }

    .icons {
        padding-right: 17%;
    }
}

@media (max-width: 1281px) {
    .carousel {
        padding-left: 5%;
        padding-right: 5%;
    }

    .about-wrapper {
        padding-right: 3%;
        padding-left: 5%;
    }

    .career-wrapper {
        gap: 9%;
    }

}

@media (max-width: 1025px) {
    .heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 31px;
        letter-spacing: -0.02em;
        text-transform: capitalize;
        color: #201F1F;
    }

    .group {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 2.086px 5.214px 0px rgba(0, 0, 0, 0.10), 0px 10.428px 10.428px 0px rgba(0, 0, 0, 0.09), 0px 22.943px 13.557px 0px rgba(0, 0, 0, 0.05), 0px 41.714px 16.685px 0px rgba(0, 0, 0, 0.01), 0px 64.656px 17.728px 0px rgba(0, 0, 0, 0.00);
    }

    .footerTxt {
        margin-left: 40px;
        gap: 60px;
    }

    .footerInfo {
        margin-left: 44px;
    }

    .cards {
        column-gap: 4px;
    }

    .techlogo {
        scale: 0.6;
    }

    .revcards {
        scale: 0.8;
    }

    .nav-item {
        margin-right: 2px;
    }

    .image-logo {
        scale: 0.9;
    }

    .get-qoute {
        font-size: 12px;
        width: 173px;
        height: 40px;
        align-self: center;
    }

    .ceomaintxt p {
        font-size: 12px;
        width: 100%;
    }


    .ceomaintxt h1 {
        width: fit-content;
    }

    .ceomain {
        gap: 0;
    }

    .ceomaintxt {
        width: 496px;
        margin-bottom: 0px;
        padding-left: 5%;
        padding-right: 2%;
    }


    .grp1 {
        scale: 0.7;
    }

    .about-wrapper {
        padding-right: 0;
    }

    .content h1 {
        font-size: 50px;
    }

    .content p {
        font-size: 13px;
    }

    .workhead {
        width: 100%;
    }

    #work .h1Card {
        scale: 0.8;
    }

    .career-wrapper .header-img {
        scale: 0.8;
    }

    .section-cards-mob {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .mob-cards {
        display: block;
        position: relative;
        z-index: 3;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15%;
    }

    .group {
        filter: none;
    }

    .mob-cards .slick-prev:before,
    .slick-next:before {
        position: absolute;
        left: -70%;
        z-index: 99999;
        display: none;
    }

    .mob-cards .slick-prev {
        left: -38px;
        display: none;
    }

    .mob-cards .slick-dots {
        display: block !important;
        bottom: -20%;
    }

    .mob-cards .slick-dots li button:before {
        color: #17BAB5;
        font-size: 15px;
    }

    .section-cards {
        display: none;
    }


}

@media (max-width: 914px) {
    .content p {
        font-size: 10px;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-collapse {
        position: absolute;
        display: inline-flex;
        padding: 20.5px 21px 24.5px 20px;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        width: 82%;
        top: 50px;
        right: 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
    }

    .navbar-collapse .navbar-nav {
        align-items: flex-start;
        width: 81%;
        background: white;
        border-radius: 8px;
        padding: 0px 0px 20px 20px;
    }

    .dropdown-menu {
        background: white !important;
        border: none;
        text-align: start;
    }

    .nav-item {
        text-align: start;
    }

    .dropdown-item {
        color: #0E8581;
        font-family: 'Inter';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 12px;
    }

    .ceomaintxt {
        width: fit-content;
    }

    .service2con {
        gap: 5px;
    }

    .career-wrapper {
        flex-direction: column;
        padding-top: 25%;
        width: 100%;
    }

    .career-wrapper .header-img {
        width: 100%;
        scale: 1;
    }

    .header-img-cube {
        display: none;
    }

    .content {
        padding-bottom: 10%;
    }

    .ceomain {
        flex-direction: column;
        padding: 15% 5%;
        width: 100%;
    }

    .service1 img {
        width: 100%;
    }

    .workpro {
        flex-direction: column;
    }

    .homepage-wrapper {
        width: 100%;
        display: flex;
        padding: 0% 7%;
        justify-content: space-between;
        padding-top: 17%;
    }

    #header {
        width: 100%;
        background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
        position: relative;
        margin-top: -9.5%;
    }
}

@media (max-width: 900px) {
    .endTxt-mob {
        display: flex;
        width: 90%;
        margin: auto;
        padding-bottom: 5%;
        align-items: center;
        gap: 60px;
    }

    .endTxt-mob img {
        width: 35%;
    }

    .footerTxt-mob p a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.3px;
        /* 143% */
        text-transform: capitalize;
    }

    .dash-line {
        display: none;
    }

    .icons,
    .endTxt {
        display: none;
    }

    .footerheading h1 {
        color: #FFF;

        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        /* 214.286% */
        text-transform: capitalize;
    }

    .footerInfo {
        display: none;
    }

    .footerInfo-mob {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 44px;
    }

    .icons-mob {
        display: flex;
        margin: auto;
        flex-direction: column;
        gap: 20px;
        margin-top: 2%;
        width: 100%;
        padding: 0px 7%;
        padding-bottom: 10%;
    }

    .footerInfo-mob .address {
        width: fit-content;
    }

    .footerInfo-mob h2 {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12.19px;
        font-style: normal;
        font-weight: 700;
        line-height: 20.317px;
        /* 166.667% */
        width: fit-content;
    }

    .footerInfo-mob h2 a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.317px;
        /* 203.174% */
    }

    .footerheading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
    }

    .footerMain .footer {
        flex-direction: column;
        gap: 50px;
    }

    .footerTxt {
        display: none;
    }

    .footerTxt-mob {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 44px;
    }

    .footerTxt-mob p {
        color: #FFF;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 39px;
        text-transform: capitalize;
    }

    .endTxt-mob h3 {
        font-size: 10.445px;
        margin: 0;
    }

    .FAQs .FAQ1 {
        width: 90% !important;
        margin: 12px auto;
    }

    .FAQ1b.visible {
        width: 90% !important;
        margin: 12px auto;
    }

    .powering-tag p {
        text-align: center;
        width: 95%;
    }

    .thankyou .thankyoutxt {
        display: flex;
        margin-top: 25%;
        flex-direction: column;
        width: 85%;
    }
}

@media (max-width: 884px) {
    .header-img-container {
        display: none;
    }

    .service2 {
        width: 100%;
    }

    .service2txt p {
        width: 90%;
    }

    .service2con {
        flex-direction: column;
    }

    .service3 {
        width: 100%;
    }

    .service3txt p {
        width: 90%;
    }
}

@media (max-width: 857px) {}

@media (max-width: 853px) {}

@media (max-width: 839px) {}

@media (max-width: 834px) {}

@media (max-width: 820px) {}

@media (max-width: 810px) {}

@media (max-width: 800px) {}

@media (max-width: 768px) {

}

@media (max-width: 615px) {
    .endTxt-mob {
        display: flex;
        width: 90%;
        margin: auto;
        padding-bottom: 5%;
        align-items: center;
        gap: 60px;
    }

    .endTxt-mob img {
        width: 35%;
    }

    .footerTxt-mob p a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.3px;
        /* 143% */
        text-transform: capitalize;
    }

    .dash-line {
        display: none;
    }

    .icons,
    .endTxt {
        display: none;
    }

    .footerheading h1 {
        color: #FFF;

        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        /* 214.286% */
        text-transform: capitalize;
    }

    .footerInfo {
        display: none;
    }

    .footerInfo-mob {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 44px;
    }

    .icons-mob {
        display: flex;
        margin: auto;
        flex-direction: column;
        gap: 20px;
        margin-top: 2%;
        width: 100%;
        padding: 0px 7%;
        padding-bottom: 10%;
    }

    .footerInfo-mob .address {
        width: fit-content;
    }

    .footerInfo-mob h2 {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12.19px;
        font-style: normal;
        font-weight: 700;
        line-height: 20.317px;
        /* 166.667% */
        width: fit-content;
    }

    .footerInfo-mob h2 a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.317px;
        /* 203.174% */
    }

    .footerheading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
    }


    .image-logo {
        width: 190.943px;
        height: 40px;
        flex-shrink: 0;
    }

    .header-img-cube {
        display: none;
    }

    .content {
        padding-bottom: 10%;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-collapse {
        position: absolute;
        display: inline-flex;
        padding: 20.5px 21px 24.5px 20px;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        width: 82%;
        top: 50px;
        right: 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
    }

    .navbar-collapse .navbar-nav {
        align-items: flex-start;
        width: 81%;
        background: white;
        border-radius: 8px;
        padding: 0px 0px 20px 20px;
    }

    .dropdown-menu {
        background: white !important;
        border: none;
        text-align: start;
    }

    .nav-item {
        text-align: start;
    }

    .dropdown-item {
        color: #0E8581;
        font-family: 'Inter';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 12px;
    }

    .get-qoute {
        width: 173px;
        align-self: flex-start;
    }

    .loader {
        position: absolute;
        width: 100%;
        height: 105vh;
        background: rgb(0, 0, 0);
        z-index: 99999;
        margin-top: -25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-bg {
        position: absolute;
        width: 40%;
        height: 33%;
        z-index: 1;
        background: rgb(0, 0, 0);
        animation: loader 20s ease-in-out;
        top: 25%;
    }

    @keyframes loader {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-50%);
        }
    }

    .modal.show .modal-dialog {
        transform: none;
        display: flex;
        justify-content: center;
        margin-top: 30%;
    }

    .footerMain .footer {
        flex-direction: column;
        gap: 50px;
    }

    .footerTxt {
        display: none;
    }

    .footerTxt-mob {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 44px;
    }

    .footerTxt-mob p {
        color: #FFF;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 39px;
        text-transform: capitalize;
    }

    .endTxt-mob h3 {
        font-size: 10.445px;
        margin: 0;
    }

    .dash-line {
        margin: 4% 0%;
    }

    /* REVIEWS */


    .reviewcon h1 {
        text-align: center;
    }

    .carousel .slick-prev::before {
        left: 0px;
        z-index: 99999;
        display: none;
    }

    .carousel .slick-next {
        right: 0px;
    }

    .carousel .slick-dots {
        display: block !important;
        bottom: 0%;
    }

    .carousel .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }


    /* FAQS */

    .FAQs .FAQ1 {
        width: 90% !important;
        margin: 12px auto;
    }

    .FAQ1b.visible {
        width: 90% !important;
        margin: 12px auto;
    }


    /* CLIENTS*/

    #logos .clientscon h2 {
        text-align: center;
    }

    .slick-slide div {
        margin: 0.1rem;
    }

    #logos .box {
        height: 100%;
    }



    .request button {
        width: 173px;
    }

    .request {
        padding-bottom: 12%;
    }


    /* POWERING*/

    .powering .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        padding-left: 12%;
    }

    .cards .data h1 {
        font-size: 24px;
        margin-top: 10px;
    }

    .cards .data p {
        margin-top: 0;
    }

    .powering .bgg {
        object-fit: inherit;
    }

    .cards .data {
        width: fit-content;
        height: 100%;
    }

    .powering-tag h1 {
        text-align: center;
    }

    .powering-tag p {
        text-align: center;
        width: 90%;
    }

    /*HOMEPAGEWRAPPER*/

    .homepage-wrapper {
        flex-direction: column;
        padding-top: 30%;
        gap: 35px;
    }

    .content h1 {
        font-size: 47px;
    }

    .section-cards {
        display: none;
    }

    .mob-cards {
        display: block;
        position: relative;
        z-index: 3;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15%;
    }

    .group {
        filter: none;
    }

    .mob-cards .slick-prev:before,
    .slick-next:before {
        position: absolute;
        left: -70%;
        z-index: 99999;
        display: none;
    }

    .mob-cards .slick-prev {
        left: -38px;
        display: none;
    }

    .mob-cards .slick-dots {
        display: block !important;
        bottom: -20%;
    }

    .mob-cards .slick-dots li button:before {
        color: #17BAB5;
        font-size: 15px;
    }

    .Agency-tag h3 {
        width: 90%;
    }

    /*ABOUT PAGE*/

    .about-wrapper {
        flex-direction: column;
        padding-top: 30%;
        padding-right: 2%;
        gap: 50px;
    }

    .header-img-container {
        display: flex;
        align-self: center;
        padding: 0 2%;
    }

    .grp1 {
        scale: 1;
        width: 100%;
    }

    .aboutceo2 .ceomaintxt {
        order: 2;
    }

    .ceomain {
        flex-direction: column;
        padding: 15% 5%;
        width: 100%;
    }

    .ceomaintxt {
        width: 100%;
        margin-top: 10%;
    }

    .about1img {
        width: 100%;
    }

    .aboutcontxt {
        width: 100%;
    }

    .aboutcontxt h3 {
        width: 90%;
    }

    .aboutdata {
        display: none;
    }

    .aboutdata-mob {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        margin-top: 50px;
    }

    .aboutmobcards-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutmobcards {
        width: 90%;
        margin: 23px auto;
        background: #F4F4F4;
        border-radius: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 5px;
    }

    .aboutimg {
        border-radius: 17.995px;
    }

    .aboutmobcards-main h1 {

        color: #17BAB5;
        font-size: 24px;
        font-family: 'Poppins';
        font-weight: 600;
        line-height: 31.439px;
        letter-spacing: -0.48px;
        text-transform: capitalize;
    }

    .aboutmobcards h1 {
        color: #3B3E3E;
        text-align: center;
        font-size: 18.651px;
        font-family: 'Poppins';
        font-weight: 500;
        line-height: 35.358px;
        letter-spacing: -0.373px;
    }

    .aboutmobcards p {
        color: #696969;
        text-align: center;
        font-size: 11.044px;
        font-family: 'Poppins';
        line-height: 20.036px;
        width: 280px;
    }

    .request button {
        font-size: 12px;
    }

    .brand h3 {
        width: 92%;
    }



    .service1 img {
        width: 100%;
    }

    .service2 {
        width: 100%;
    }

    .service2txt p {
        width: 90%;
    }

    .service2con {
        flex-direction: column;
    }

    .service3 {
        width: 100%;
    }

    .service3txt p {
        width: 90%;
    }


    .work-wrapper {
        flex-direction: column;
        padding-top: 30%;
    }

    #work .work1txt p {
        width: 90%;
        font-size: 10px;
    }

    .workpro {
        flex-direction: column;
    }

    .worktxt h1 {
        width: 90%;
    }

    .worktxt p {
        width: 90%;
    }

    .workhead {
        width: 100%;
    }

    .header-img img {
        width: 100%;
    }



    .career-wrapper {
        flex-direction: column;
        padding-top: 25%;
        width: 100%;
    }

    .career-wrapper .header-img {
        width: 100%;
        scale: 1;
    }

    .career1txt,
    .career2txt,
    .career3txt p {
        width: 100%;
    }

    .career1txt p,
    .career2txt p,
    .career3txt p,
    .career4txt p {
        width: 90%;
    }

    .career1con,
    .career2con,
    .career3con {
        flex-direction: column;
    }

    .career1txt h1 {
        width: 90%;
    }

    .career1con .con1,
    .career2con,
    .con2,
    .career3con .con1 {
        width: 90% !important;
        margin: auto;
    }

    .career1con .con2img,
    .career2con .con2img,
    .career3con .con2img {
        width: 100%;
    }

    .career1con .con2,
    .career2con .con1 {
        width: 90%;
        margin: auto;
    }

    .career2con .con2 p {
        width: 90%;
    }

    .career2 {
        padding-bottom: 5%;
    }

    .contact1 .form {
        width: 95%;
        margin: auto;
    }

    .form .formcon {
        flex-direction: column;
        gap: 15px;
    }

    .formcon2 input {
        width: 100%;
    }

    .contact2 {
        flex-direction: column;

    }

    /*NAVBAR*/
    ul {
        display: flex;

    }


    /* BLOGS */

    .blog1txt p {
        width: 90%;
    }

    .blog1txt {
        margin-top: 40%;
    }

    .object-list {
        padding-left: 2%;
        margin: auto;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 40px;
    }

    .object-item {
        width: 94%;
    }

    .searchbar input {
        width: 85%;
        padding-left: 18%;
    }

    .service3txt h1 {
        width: 90%;
    }

    .sendbutton {
        align-self: center;
        margin: 5% auto
    }

    .brand {
        padding-bottom: 5%;
    }


    .reviewcon h2 {
        text-align: center;
    }

    .revtext {
        width: 90%;
    }
}

@media (max-width: 601px) {
    .endTxt-mob {
        display: flex;
        width: 90%;
        margin: auto;
        padding-bottom: 5%;
        align-items: center;
        gap: 137px;
    }

    #header {
        width: 100%;
        background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
        position: relative;
        margin-top: -12.5%;
    }

    .contact1 .form {
        width: 95%;
        margin: auto;
    }

    .form .formcon {
        flex-direction: column;
        gap: 15px;
    }

    .form input,
    .form select {
        width: 100%;
    }

    .formcon2 input {
        width: 96%;
    }

    .formcon .inputs {
        width: 100%;
    }

    .formcon .input2 {
        width: 100%;
    }

    .form .text {
        width: 96%;
    }
}

@media (max-width: 540px) {}

@media (max-width: 500px) {}

@media (max-width: 480px) {

    .contact1 .form {
        width: 88%;
        padding: 5%;
        gap: 20px;
    }

    .form input,
    .form select {
        width: 100%;
    }
}



@media(max-width:431px) {

    .thankyoutxt h1 {
        font-size: 15px;
    }

    .thankyoutxt p {
        font-size: 12px;
    }

    .thankyou .thankyoutxt {
        margin-top: 70%;
        width: 90%;
        padding: 6%;
    }


    .formcon2 input {
        width: 100%;
    }


    .form .text {
        width: 100%;
    }

    .endTxt-mob {
        display: flex;
        width: 90%;
        margin: auto;
        padding-bottom: 5%;
        align-items: center;
        gap: 60px;
    }

    .endTxt-mob img {
        width: 35%;
    }

    .footerTxt-mob p a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14.3px;
        /* 143% */
        text-transform: capitalize;
    }

    .dash-line {
        display: none;
    }

    .icons,
    .endTxt {
        display: none;
    }

    .footerheading h1 {
        color: #FFF;

        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        /* 214.286% */
        text-transform: capitalize;
    }

    .footerInfo {
        display: none;
    }

    .footerInfo-mob {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: 44px;
    }

    .icons-mob {
        align-items: flex-start;
        padding-bottom: 10%;
    }

    .footerInfo-mob .address {
        width: fit-content;
    }

    .footerInfo-mob h2 {
        color: #FFF;
        font-family: 'Inter';
        font-size: 12.19px;
        font-style: normal;
        font-weight: 700;
        line-height: 20.317px;
        /* 166.667% */
        width: fit-content;
    }

    .footerInfo-mob h2 a {
        color: #FFF;
        font-family: 'Inter';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.317px;
        /* 203.174% */
    }

    .footerheading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
    }


    .image-logo {
        width: 190.943px;
        height: 40px;
        flex-shrink: 0;
    }

    .header-img-cube {
        display: none;
    }

    .content {
        padding-bottom: 10%;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-collapse {
        position: absolute;
        display: inline-flex;
        padding: 20.5px 21px 24.5px 20px;
        flex-direction: column;
        align-items: flex-end;
        gap: 23px;
        width: 82%;
        top: 50px;
        right: 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
    }

    .navbar-collapse .navbar-nav {
        align-items: flex-start;
        width: 81%;
        background: white;
        border-radius: 8px;
        padding: 0px 0px 20px 20px;
    }

    .dropdown-menu {
        background: white !important;
        border: none;
        text-align: start;
    }

    .nav-item {
        text-align: start;
    }

    .dropdown-item {
        color: #0E8581;
        font-family: 'Inter';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 12px;
    }

    .get-qoute {
        width: 173px;
        align-self: flex-start;
    }

    .loader {
        position: absolute;
        width: 100%;
        height: 105vh;
        background: rgb(0, 0, 0);
        z-index: 99999;
        margin-top: -25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-bg {
        position: absolute;
        width: 40%;
        height: 33%;
        z-index: 1;
        background: rgb(0, 0, 0);
        animation: loader 20s ease-in-out;
        top: 25%;
    }

    @keyframes loader {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-50%);
        }
    }

    .modal.show .modal-dialog {
        transform: none;
        display: flex;
        justify-content: center;
        margin-top: 30%;
    }

    .footerMain .footer {
        flex-direction: column;
        gap: 50px;
    }

    .footerTxt {
        display: none;
    }

    .footerTxt-mob {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 44px;
    }

    .footerTxt-mob p {
        color: #FFF;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 39px;
        text-transform: capitalize;
    }

    .endTxt-mob h3 {
        font-size: 10.445px;
        margin: 0;
    }

    .dash-line {
        margin: 4% 0%;
    }

    .icons-mob {
        display: flex;
        margin: auto;
        flex-direction: column;
        gap: 20px;
        margin-top: 2%;
        width: 100%;
        padding: 0 11%;
        padding-bottom: 10%;
    }


    /* REVIEWS */


    .reviewcon h1 {
        text-align: center;
    }

    .carousel .slick-prev::before {
        left: 0px;
        z-index: 99999;
        display: none;
    }

    .carousel .slick-next {
        right: 0px;
    }

    .carousel .slick-dots {
        display: block !important;
        bottom: 0%;
    }

    .carousel .slick-dots li button:before {
        color: #17BAB5;
        font-size: 20px;
    }


    /* FAQS */

    .FAQs .FAQ1 {
        width: 90% !important;
        margin: 12px auto;
    }

    .FAQ1b.visible {
        width: 90% !important;
        margin: 12px auto;
    }


    /* CLIENTS*/

    #logos .clientscon h2 {
        text-align: center;
    }

    .slick-slide div {
        margin: 0.1rem;
    }

    #logos .box {
        height: 100%;
    }



    .request button {
        width: 173px;
    }

    .request {
        padding-bottom: 12%;
    }


    /* POWERING*/

    .powering .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
        padding-left: 12%;
    }

    .cards .data h1 {
        font-size: 24px;
        margin-top: 10px;
    }

    .cards .data p {
        margin-top: 0;
    }

    .powering .bgg {
        object-fit: inherit;
    }

    .cards .data {
        width: fit-content;
        height: 100%;
    }

    .powering-tag h1 {
        text-align: center;
    }

    .powering-tag p {
        text-align: center;
        width: 90%;
    }

    /*HOMEPAGEWRAPPER*/

    .homepage-wrapper {
        flex-direction: column;
        padding-top: 30%;
        gap: 35px;
    }

    .content h1 {
        font-size: 47px;
    }

    .section-cards {
        display: none;
    }

    .mob-cards {
        display: block;
        position: relative;
        z-index: 3;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15%;
    }

    .group {
        filter: none;
    }

    .mob-cards .slick-prev:before,
    .slick-next:before {
        position: absolute;
        left: -70%;
        z-index: 99999;
        display: none;
    }

    .mob-cards .slick-prev {
        left: -38px;
        display: none;
    }

    .mob-cards .slick-dots {
        display: block !important;
        bottom: -20%;
    }

    .mob-cards .slick-dots li button:before {
        color: #17BAB5;
        font-size: 15px;
    }

    .Agency-tag h3 {
        width: 90%;
    }

    /*ABOUT PAGE*/

    .about-wrapper {
        flex-direction: column;
        padding-top: 30%;
        padding-right: 2%;
        gap: 50px;
    }

    .header-img-container {
        display: flex;
        align-self: center;
        padding: 0 2%;
    }

    .grp1 {
        scale: 1;
        width: 100%;
    }

    .aboutceo2 .ceomaintxt {
        order: 2;
    }

    .ceomain {
        flex-direction: column;
        padding: 15% 5%;
        width: 100%;
    }

    .ceomaintxt {
        width: 100%;
        margin-top: 10%;
    }

    .about1img {
        width: 100%;
    }

    .aboutcontxt {
        width: 100%;
    }

    .aboutcontxt h3 {
        width: 90%;
    }

    .aboutdata {
        display: none;
    }

    .aboutdata-mob {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        margin-top: 50px;
    }

    .aboutmobcards-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .aboutmobcards {
        width: 90%;
        margin: 23px auto;
        background: #F4F4F4;
        border-radius: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 5px;
    }

    .aboutimg {
        border-radius: 17.995px;
    }

    .aboutmobcards-main h1 {

        color: #17BAB5;
        font-size: 24px;
        font-family: 'Poppins';
        font-weight: 600;
        line-height: 31.439px;
        letter-spacing: -0.48px;
        text-transform: capitalize;
    }

    .aboutmobcards h1 {
        color: #3B3E3E;
        text-align: center;
        font-size: 18.651px;
        font-family: 'Poppins';
        font-weight: 500;
        line-height: 35.358px;
        letter-spacing: -0.373px;
    }

    .aboutmobcards p {
        color: #696969;
        text-align: center;
        font-size: 11.044px;
        font-family: 'Poppins';
        line-height: 20.036px;
        width: 280px;
    }

    .request button {
        font-size: 12px;
    }

    .brand h3 {
        width: 92%;
    }



    .service1 img {
        width: 100%;
    }

    .service2 {
        width: 100%;
    }

    .service2txt p {
        width: 90%;
    }

    .service2con {
        flex-direction: column;
    }

    .service3 {
        width: 100%;
    }

    .service3txt p {
        width: 90%;
    }


    .work-wrapper {
        flex-direction: column;
        padding-top: 30%;
    }

    #work .work1txt p {
        width: 90%;
        font-size: 10px;
    }

    .workpro {
        flex-direction: column;
    }

    .worktxt h1 {
        width: 90%;
    }

    .worktxt p {
        width: 90%;
    }

    .workhead {
        width: 100%;
    }

    .header-img img {
        width: 100%;
    }



    .career-wrapper {
        flex-direction: column;
        padding-top: 25%;
        width: 100%;
    }

    .career-wrapper .header-img {
        width: 100%;
        scale: 1;
    }

    .career1txt,
    .career2txt,
    .career3txt p {
        width: 100%;
    }

    .career1txt p,
    .career2txt p,
    .career3txt p,
    .career4txt p {
        width: 90%;
    }

    .career1con,
    .career2con,
    .career3con {
        flex-direction: column;
    }

    .career1txt h1 {
        width: 90%;
    }

    .career1con .con1,
    .career2con,
    .con2,
    .career3con .con1 {
        width: 90% !important;
        margin: auto;
    }

    .career1con .con2img,
    .career2con .con2img,
    .career3con .con2img {
        width: 100%;
    }

    .career1con .con2,
    .career2con .con1 {
        width: 90%;
        margin: auto;
    }

    .career2con .con2 p {
        width: 90%;
    }

    .career2 {
        padding-bottom: 5%;
    }

    .contact1 .form {
        width: 95%;
        margin: auto;
    }

    .form .formcon {
        flex-direction: column;
        gap: 15px;
    }

    .formcon2 input {
        width: 100%;
    }

    .formcon .inputs {
        width: 100%;
    }

    .formcon .input2 {
        width: 100%;
    }

    .form .text {
        width: 100%;
    }

    .contact2 {
        flex-direction: column;

    }

    /*NAVBAR*/
    ul {
        display: flex;

    }


    /* BLOGS */

    .blog1txt p {
        width: 90%;
    }

    .blog1txt {
        margin-top: 40%;
    }

    .object-list {
        padding-left: 2%;
        margin: auto;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 40px;
    }

    .object-item {
        width: 94%;
    }

    .searchbar input {
        width: 85%;
        padding-left: 18%;
    }

    .service3txt h1 {
        width: 90%;
    }

    .sendbutton {
        align-self: center;
        margin: 5% auto
    }

    .brand {
        padding-bottom: 5%;
    }


    .reviewcon h2 {
        text-align: center;
    }

    .revtext {
        width: 90%;
    }
}

@media (max-width: 428px) {}

@media (max-width: 415px) {
    .form .formcon {
        flex-direction: column;
        gap: 15px;
        width: 100%;
        justify-content: center;
    }

    .sendbutton {
        align-self: center;
        margin-bottom: 5%;
    }

    .endTxt-mob {
        gap: 50px;
    }
}

@media (max-width: 393px) {}

@media (max-width: 391px) {
    .request button {
        width: 155px;
    }

    .revcards {
        scale: 0.8;
        width: 92%;
    }

    .content p {
        font-size: 12px;
        margin: 0;
    }

    .endTxt-mob {
        gap: 35px;
    }
}

@media (max-width: 376px) {
    .content p {
        font-size: 11px;
    }

    .content h1 {
        font-size: 40px;
        line-height: 40px;
    }

    .header-img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mob-cards {
        width: 60%;
    }

    .mob-cards .slick-prev:before,
    .slick-next:before {
        left: -40%;
    }

    .request button {
        width: 152px;
        font-size: 11px;
    }

    .revcards {
        scale: 0.7;
    }

    .endTxt h3 {
        font-size: 8.445px;
        margin: 0;
    }

    .contact1txt p {
        width: 90%;
    }

    .form .formcon {
        flex-direction: column;
        gap: 15px;
        width: 100%;
        justify-content: center;
    }

    .formcon2 .formdata {
        margin: auto;
    }

    .sendbutton {
        align-self: center;
        margin-bottom: 5%;
    }

    .endTxt-mob {
        gap: 30px;
    }
}

@media (max-width: 361px) {
    .revcards {
        scale: 0.8;
        width: 92%;
    }

    #work .work1txt h1 {
        margin-top: 35px;
    }

    #work .h1Card {
        height: 100%;
        scale: 0.9;
    }

    .endTxt-mob {
        gap: 22px;
    }
}

@media (max-width: 320px) {}

@media (max-width:280px) {}

a {
    text-decoration: none;
}

.disable-scroll {
    overflow: hidden;
}