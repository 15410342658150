@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;800&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Poppins:wght@400;500;800&family=Varela+Round&display=swap');


.plans-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: -5%;
    padding: 15% 0;
    background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);
}

.plans-banner h1 {
    font-family: 'Inter';
    font-size: 52.822px;
    font-style: normal;
    font-weight: 800;
    line-height: 109.523%;
    /* 57.853px */
    text-transform: capitalize;

    background: linear-gradient(270deg, #048985 25.83%, #35CDC8 97.85%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.plans-banner p {
    color: #696969;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.249px;
    /* 141.243% */
    text-transform: capitalize;
}

.plan-buttons {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    background: #F0F0F0;
    box-shadow: 0px 12px 26px 0px rgba(0, 0, 0, 0.10), 0px 47px 47px 0px rgba(0, 0, 0, 0.09), 0px 106px 64px 0px rgba(0, 0, 0, 0.05), 0px 189px 75px 0px rgba(0, 0, 0, 0.01), 0px 295px 83px 0px rgba(0, 0, 0, 0.00);

}

.plan-buttons button {
    display: flex;
    width: 175.074px;
    padding: 8.458px;
    justify-content: center;
    align-items: center;
    gap: 8.458px;

    border-radius: 33.831px;
    border: none;
    background: #1C1C1C;
    box-shadow: 0px 0.84577px 2.5373px 0px rgba(0, 0, 0, 0.10), 1.69153px 4.22883px 5.0746px 0px rgba(0, 0, 0, 0.09), 3.38307px 10.1492px 6.76614px 0px rgba(0, 0, 0, 0.05), 5.92037px 18.60687px 7.6119px 0px rgba(0, 0, 0, 0.01), 8.45767px 28.75608px 8.45767px 0px rgba(0, 0, 0, 0.00);

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15.224px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.361px;
    /* 160.017% */
    text-transform: capitalize;

}

.plan-buttons button:hover {
    border-radius: 33.831px;
    background: linear-gradient(180deg, #17B5B0 0%, #0A9B96 100%);
    box-shadow: 0px 0.84577px 2.5373px 0px rgba(0, 0, 0, 0.10), 1.69153px 4.22883px 5.0746px 0px rgba(0, 0, 0, 0.09), 3.38307px 10.1492px 6.76614px 0px rgba(0, 0, 0, 0.05), 5.92037px 18.60687px 7.6119px 0px rgba(0, 0, 0, 0.01), 8.45767px 28.75608px 8.45767px 0px rgba(0, 0, 0, 0.00);
}

.plan-buttons button:nth-child(3) {
    width: 247.894px;
    padding: 10px;
}

.bg-div {
    background: linear-gradient(rgba(226, 246, 246, 0.4) 0%, rgba(23, 186, 181, 0.4) 100%);
    height: 132px
}

.plans-section {
    display: flex;
    width: 80%;
    height: 100%;
    margin: auto;
    gap: 40px;
    align-items: stretch;
    justify-content: center;
    padding: 3% 0 6% 0;

}

.plans-section-context {
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #17BAB5 0%, #0E8581 100%);
    align-items: center;
    justify-content: center;
    padding: 3% 0;
}

.plans-section-context h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Varela Round';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.plans-section-context p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    text-transform: capitalize;
    width: 52%;
}


.header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 25px;
}

.header-div h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 22px */
    letter-spacing: -0.6px;
}

.header-div p {
    color: rgba(255, 255, 255, 0.70);
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    /* 143.333% */
    letter-spacing: -0.45px;
}


.plans-second {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.subsection {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    height: 100%;
    gap: 14px;
    padding-top: 20px;
}

.subsection-section {
    display: flex;
    align-items: baseline;
    justify-content: start;
    gap: 4px;
    padding: 0 32px;
}

.subsection-section p {
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 220%;
    /* 28.6px */
    letter-spacing: -0.13px;
    margin-bottom: 0;
}

.payment-subsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 54px;
}

.payment-subsection .subsection-section {
    display: flex;
    align-items: start;

}


.payment-subsection h2 {
    color: rgba(255, 255, 255, 0.70);
    text-align: center;
    font-family: 'Poppins';
    font-size: 49.2px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.528px;
    letter-spacing: -1.476px;
}

.payment-subsection p {
    color: #BBB;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.45px;
    width: 23%;

}

.blacktext {
    background: #17BAB5;
    color: black;
}

.defaulttext {
    background: #232323;
    color: #FFF;
}

.textblack {
    color: black;
}


.plans {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    width: 100%;
    background: #1C1C1C;

}


.subscribe-button {
    display: flex;
    width: 229px;
    height: 42px;
    padding: 11.036px 35.314px;
    justify-content: center;
    align-items: center;
    gap: 11.036px;
    flex-shrink: 0;

    border-radius: 20px;
    border: 0.5px solid #17BAB5;
    box-shadow: 0px 5px 12px 0px rgba(23, 186, 181, 0.10), 0px 21px 21px 0px rgba(23, 186, 181, 0.09), 0px 48px 29px 0px rgba(23, 186, 181, 0.05), 0px 86px 34px 0px rgba(23, 186, 181, 0.01), 0px 134px 37px 0px rgba(23, 186, 181, 0.00);
    background: #1C1C1C;

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    /* 19.8px */
    letter-spacing: -0.54px;

    margin-bottom: 64px;


}

.middle-button {
    border-radius: 20px;
    border: 0.5px solid #17BAB5;
    background: linear-gradient(180deg, #17BAB5 0%, #17BAB5 100%);
    box-shadow: 0px 5px 12px 0px rgba(23, 186, 181, 0.10), 0px 21px 21px 0px rgba(23, 186, 181, 0.09), 0px 48px 29px 0px rgba(23, 186, 181, 0.05), 0px 86px 34px 0px rgba(23, 186, 181, 0.01), 0px 134px 37px 0px rgba(23, 186, 181, 0.00);
}



.vibrant-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    background: #222;
    gap: 60px;
}

.socialmedia-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5% 0;
    background: linear-gradient(180deg, #17BAB5 0%, #0E8581 100%);
    gap: 60px;
}

.vibrant-context {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vibrant-context h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Varela Round';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
}

.vibrant-context p {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.5px;
    /* 143.333% */
    text-transform: capitalize;
    width: 70%;
}

.vibrant-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.socialmedia-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.socialmedia-buttons button {
    display: flex;
    width: 207px;
    padding: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    border: none;
    background: black;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 2px 5px 6px 0px rgba(0, 0, 0, 0.09), 4px 12px 8px 0px rgba(0, 0, 0, 0.05), 7px 22px 9px 0px rgba(0, 0, 0, 0.01), 10px 34px 10px 0px rgba(0, 0, 0, 0.00);

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.803px;
    /* 160.017% */
    text-transform: capitalize;
}

.vibrant-buttons button {
    display: flex;
    width: 207px;
    padding: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 40px;
    border: none;
    background: linear-gradient(180deg, #17BAB5 0%, #077471 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 2px 5px 6px 0px rgba(0, 0, 0, 0.09), 4px 12px 8px 0px rgba(0, 0, 0, 0.05), 7px 22px 9px 0px rgba(0, 0, 0, 0.01), 10px 34px 10px 0px rgba(0, 0, 0, 0.00);

    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.803px;
    /* 160.017% */
    text-transform: capitalize;
}

.vibrant-buttons button:nth-child(2) {
    background: #fff;
    color: #292828;
}

.socialmedia-buttons button:nth-child(2) {
    background: #fff;
    color: #292828;
}

.plans-mob {
    display: none;
}

.plan-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(226, 246, 246, 0.15) 0%, rgba(23, 186, 181, 0.15) 100%);

}

.plan-brand h3 {
    text-align: center;
    color: #696969;
    font-size: 15px;
    width: 825px;
    font-family: 'Poppins';
    line-height: 21.5px;
    margin-top: 20px;
    text-transform: capitalize;
}

.plan-brand h1 {
    font-family: 'Varela Round';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    margin-top: 115px;
}

a {
    text-decoration: none;
    color: white;
}

.plans-section-mobile {
    display: none;
}









@media (max-width: 900px) {
    .plans-banner h1 {
        font-size: 40px;
    }

    .plans-banner p {
        font-size: 14px;
        width: 84%;
    }

    .plan-buttons {
        flex-direction: column;
    }

    .plan-buttons button {
        font-size: 13.736px;
    }

    .bg-div {
        display: none;
    }

    .plans-section-context {
        padding-top: 15%;
    }

    .plans-section-context h1 {
        font-size: 20px;
    }

    .plans-section-context p {
        font-size: 14px;
        width: 86%;
    }

    .vibrant-section,
    .socialmedia-section {
        padding-top: 15%;
    }

    .vibrant-context h1 {
        font-size: 20px;
        width: 80%;
    }

    .vibrant-context p {
        font-size: 14px;
        width: 86%;
    }

    .vibrant-buttons button,
    .socialmedia-buttons button {
        width: 125px;
    }

    .plan-brand h1 {
        font-size: 20px;
        width: 88%;
    }

    .plan-brand h3 {
        font-size: 14px;
        width: 95%;
    }

    .plans-section {
        display: none;
    }

    .plans-section-mobile {
        display: block;
        position: relative;
        z-index: 3;
        width: 82%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .plans-section-mobile .slick-prev,
    .slick-next {
        display: none !important;
    }

    .plans-section-mobile .slick-dots {
        display: block !important;
    }

    .plans-section-mobile .slick-dots li.slick-active button:before {
        font-size: 12px;
        color: #17BAB5;
    }

    .plans-section-mobile .slick-dots button::before {
        font-size: 12px;
        color: #17BAB5;
    }
}








@media (max-width:431px) {
    .plans-banner h1 {
        font-size: 40px;
    }

    .plans-banner p {
        font-size: 14px;
        width: 84%;
    }

    .plan-buttons {
        flex-direction: column;
    }

    .plan-buttons button {
        font-size: 13.736px;
    }

    .bg-div {
        display: none;
    }

    .plans-section-context {
        padding-top: 15%;
    }

    .plans-section-context h1 {
        font-size: 20px;
    }

    .plans-section-context p {
        font-size: 14px;
        width: 86%;
    }

    .vibrant-section,
    .socialmedia-section {
        padding-top: 15%;
    }

    .vibrant-context h1 {
        font-size: 20px;
        width: 80%;
    }

    .vibrant-context p {
        font-size: 14px;
        width: 86%;
    }

    .vibrant-buttons button,
    .socialmedia-buttons button {
        width: 125px;
    }

    .plan-brand h1 {
        font-size: 20px;
        width: 88%;
    }

    .plan-brand h3 {
        font-size: 14px;
        width: 95%;
    }

    .plans-section {
        display: none;
    }

    .plans-section-mobile {
        display: block;
        position: relative;
        z-index: 3;
        width: 82%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 15%;
        padding-bottom: 15%;
    }

    .plans-section-mobile .slick-prev,
    .slick-next {
        display: none !important;
    }

    .plans-section-mobile .slick-dots {
        display: block !important;
    }

    .plans-section-mobile .slick-dots li.slick-active button:before {
        font-size: 12px;
        color: #17BAB5;
    }

    .plans-section-mobile .slick-dots button::before {
        font-size: 12px;
        color: #17BAB5;
    }
}